import Typography from '@mui/material/Typography';
import { KeyValueRow } from 'noddi-ui';

type Props = {
  header: string;
  value: string | number;
};

export const ModifiedKeyValueColumn = ({ header, value }: Props) => {
  const Header = (
    <Typography
      variant='body2'
      sx={{
        marginBottom: '1rem',
        fontWeight: 'bold'
      }}
    >
      {header}
    </Typography>
  );
  const Value = (
    <Typography
      variant='body2'
      sx={{
        whiteSpace: 'pre-wrap'
      }}
    >
      {value}
    </Typography>
  );
  return <KeyValueRow header={Header} value={Value} showDivider={false} direction='column' />;
};
