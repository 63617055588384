import Typography from '@mui/material/Typography';
import { KeyValueRow } from 'noddi-ui';
import { ReactNode } from 'react';

type ValueType = ReactNode | string | number;

type Props = {
  header: string;
  value: ValueType;
};

const getTypographyValue = (value: string | number) => <Typography variant='body2'>{value}</Typography>;

const getValue = (value: ValueType) => {
  if (typeof value === 'string' || typeof value === 'number') {
    return getTypographyValue(value);
  }
  return value;
};

export const ModifiedKeyValueRow = ({ header, value }: Props) => {
  return (
    <KeyValueRow
      header={<Typography variant='body2'>{header}</Typography>}
      value={getValue(value)}
      showDivider={false}
    />
  );
};
