import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ActiveBookingItem } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import { NoddiButton, colors } from 'noddi-ui';
import { useState } from 'react';

import CustomModal from '../../../commons/Modal';

export interface UnableToCompleteItem {
  id: number;
  name: string;
  isSelected: boolean;
}

export function UndoUnableToCompleteModal({
  isOpen,
  setIsOpen,
  routeId,
  routeItemId,
  salesItems
}: {
  isOpen: boolean;
  setIsOpen: (unableToCompleteModal: boolean) => void;
  routeId: string;
  routeItemId: string;
  salesItems: ActiveBookingItem['salesItems'];
}) {
  const itemsUnableToComplete = salesItems.filter((item) => item.unableToComplete);
  const mapSalesItemsToUnableToCompleteItems: UnableToCompleteItem[] = itemsUnableToComplete.map((item) => ({
    id: item.bookingItemSalesItemId,
    name: item.name,
    isSelected: true
  }));

  const [undoItems, setUndoItems] = useState<UnableToCompleteItem[]>(mapSalesItemsToUnableToCompleteItems);
  const { noddiToast } = useNoddiToast();

  const { mutateAsync: undoUnableToComplete, isPending: isUndoUnableToCompletePending } = noddiAsync.usePost({
    type: URLKeys.postUndoUnableToCompleteBookingItems,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteItemsDetailsForServiceWorker,
          input: { routeItemId, routeId }
        });
        noddiToast.success('Successfully undo unable to complete items');
        setIsOpen(false);
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const isAnySelected = undoItems.some((item) => item.isSelected);

  return (
    <CustomModal
      isOpen={isOpen}
      handleOpen={() => {
        setIsOpen(false);
      }}
      handleClick={() => {
        return;
      }}
      variant='form'
      text={
        <Stack>
          <Typography variant='h5' style={{ marginBottom: 12 }}>
            <Trans>Choose items to undo</Trans>
          </Typography>

          {undoItems.map((item) => {
            return (
              <Stack direction='row' alignItems='center'>
                <Typography>{item.name}</Typography>
                <Checkbox
                  style={{ color: colors.primary.orange }}
                  checked={item.isSelected}
                  onChange={(e) => {
                    setUndoItems((prev) =>
                      prev.map((undoItem) =>
                        undoItem.id === item.id ? { ...undoItem, isSelected: e.target.checked } : undoItem
                      )
                    );
                  }}
                />
              </Stack>
            );
          })}

          <NoddiButton
            style={{ marginTop: '24px' }}
            disabled={!isAnySelected}
            fullWidth
            loading={isUndoUnableToCompletePending}
            onClick={async () => {
              const req = {
                routeItemId,
                routeId,
                bookingItemSalesItemIds: undoItems.filter((item) => item.isSelected).map((item) => item.id)
              };
              await undoUnableToComplete(req);
            }}
          >
            <Trans>Undo items</Trans>
          </NoddiButton>
        </Stack>
      }
    />
  );
}
