import { Trans, t } from '@lingui/macro';
import { FormControl, MenuItem, Rating, Select, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import {
  LoadingScreen,
  NoddiAsyncError,
  NoddiCircularLoader,
  NoddiCollapseCard,
  NoddiContainer,
  PaginatedWrapper,
  colors,
  usePagination
} from 'noddi-ui';
import { DateFormats, format, subDays } from 'noddi-util';
import { useEffect, useState } from 'react';

import useAxiosPrivate from '../../adapters/xhr/axiosPrivate';
import { ApiEndpointIdentifier } from '../../constant/apiRoute';
import { getEndpointUrl } from '../../helper';
import { PaginatedResponse } from '../../types/server';

const dayFilterOptios = ['Last 14 days', 'Last 30 days', 'All time'];

interface OverallFeedback {
  avg_customer_rating_car_result: number;
  avg_customer_rating_politeness: number;
  service_feedbacks: PaginatedResponse<Feedback[]>;
}

interface Feedback {
  date: string;
  customer_rating_car_result: number;
  customer_rating_politeness?: number;
  customer_comment: string;
}

const MyFeedback = () => {
  const axiosPrivate = useAxiosPrivate();
  const { page, setPage, pageSize, setPageSize } = usePagination();
  const [dayFilterOption, setDayFilterOption] = useState(dayFilterOptios[0]);

  const endpoint =
    dayFilterOption === 'All time'
      ? ApiEndpointIdentifier.SERVICE_WORKER_FEEDBACKS
      : ApiEndpointIdentifier.SERVICE_WORKER_FEEDBACKS_WITH_DATES;
  const {
    data: feedbacks,
    isLoading,
    isPending,
    isFetching,
    isSuccess,
    refetch
  } = useQuery<OverallFeedback, NoddiAsyncError>({
    queryKey: [ApiEndpointIdentifier.SERVICE_WORKER_FEEDBACKS],
    queryFn: async () => {
      const defaultParams = {
        pageSize,
        page
      };
      const params =
        dayFilterOption === 'All time'
          ? defaultParams
          : {
              ...defaultParams,
              fromDate: format(
                subDays(new Date(), dayFilterOption === 'Last 14 days' ? 14 : 30),
                DateFormats.DASHED_DATE_ISO_8601
              ),
              toDate: format(new Date(), DateFormats.DASHED_DATE_ISO_8601)
            };
      return axiosPrivate.get(getEndpointUrl(endpoint, params)).then((res) => res.data);
    }
  });

  useEffect(() => {
    refetch();
  }, [page, pageSize, dayFilterOption]);

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!isSuccess) {
    return (
      <NoddiContainer>
        <Typography>
          <Trans>Something went wrong..</Trans>
        </Typography>
      </NoddiContainer>
    );
  }

  const isLoadingFeedbacks = isLoading || isFetching;
  const overAllPolitenessScore = Math.round(feedbacks.avg_customer_rating_politeness * 100) / 100;
  const overAllCarResultScore = Math.round(feedbacks.avg_customer_rating_car_result * 100) / 100;

  return (
    <NoddiContainer header={t`My feedback`}>
      <Stack my={2}>
        <Stack>
          <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2}>
            <Typography variant='h6'>
              <Trans>Overall summary scores</Trans>
            </Typography>
            <FormControl>
              <Select value={dayFilterOption} onChange={(e) => setDayFilterOption(e.target.value.toString())}>
                {dayFilterOptios.map((option) => {
                  return (
                    <MenuItem selected={option === dayFilterOption} key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>

          <Stack direction='row' gap={2} my={2}>
            <Stack
              justifyContent='center'
              alignItems='center'
              sx={{
                backgroundColor: colors.primary.darkPurple30,
                height: '150px',
                width: '200px',
                borderRadius: 2
              }}
            >
              <Typography variant='subtitle2'>
                ⭐ <Trans>Politeness</Trans>
              </Typography>
              <Typography variant='h4'>{overAllPolitenessScore}</Typography>
              <Typography variant='subtitle2' fontSize={10}>
                <Trans>out of 5</Trans>
              </Typography>
            </Stack>
            <Stack
              justifyContent='center'
              alignItems='center'
              sx={{
                backgroundColor: colors.primary.darkPurple30,
                height: '150px',
                width: '200px',
                borderRadius: 2
              }}
            >
              <Typography variant='subtitle2'>
                🚗 <Trans>Car result</Trans>
              </Typography>
              <Typography variant='h4'>{overAllCarResultScore}</Typography>
              <Typography variant='subtitle2' fontSize={10}>
                <Trans>out of 5</Trans>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Typography variant='h6' my={2}>
          <Trans>All Your feedbacks</Trans>
        </Typography>
        {isLoadingFeedbacks ? (
          <NoddiCircularLoader />
        ) : (
          <PaginatedWrapper
            page={page}
            setPage={setPage}
            setRowsPerPage={setPageSize}
            rowsPerPage={pageSize}
            hidden={feedbacks.service_feedbacks.results.length === 0}
            count={feedbacks.service_feedbacks.count}
          >
            <Stack>
              {feedbacks.service_feedbacks.results.map((feedback) => (
                <NoddiCollapseCard
                  key={feedback.date}
                  ExpandHelperElement={
                    feedback.customer_comment ? (
                      <Typography fontSize={10}>
                        <Trans>See customer comment</Trans>
                      </Typography>
                    ) : null
                  }
                  sx={{ marginTop: 2 }}
                  backgroundColor={colors.primary.darkPurple30}
                  header={
                    <Stack>
                      <Typography>
                        🗓️ <b>{format(new Date(feedback.date), DateFormats.FULL_MONTH_YEAR)}</b>
                      </Typography>
                      <Stack direction='row' alignItems='center' mt={2}>
                        <Typography whiteSpace='nowrap' fontSize={12}>
                          <Trans>Politenes:</Trans>
                        </Typography>
                        <Rating
                          style={{ color: colors.primary.orange }}
                          size='small'
                          name='rating'
                          precision={0.5}
                          max={5}
                          value={feedback.customer_rating_politeness}
                          readOnly
                        />
                      </Stack>
                      <Stack direction='row' alignItems='center'>
                        <Typography fontSize={12}>
                          <Trans>Car result:</Trans>
                        </Typography>
                        <Rating
                          style={{ color: colors.primary.orange }}
                          size='small'
                          name='rating'
                          precision={0.5}
                          max={5}
                          value={feedback.customer_rating_car_result}
                          readOnly
                        />
                      </Stack>
                    </Stack>
                  }
                  collapseBody={
                    <Stack>
                      {feedback.customer_comment ? (
                        <Stack>
                          <Typography variant='body2' mb={2}>
                            💬{' '}
                            <b>
                              <Trans>Customer comment</Trans>
                            </b>
                          </Typography>
                          <Typography variant='body2'>{feedback.customer_comment}</Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                  }
                />
              ))}
            </Stack>
          </PaginatedWrapper>
        )}
      </Stack>
    </NoddiContainer>
  );
};

export default MyFeedback;
