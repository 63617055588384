import { Trans } from '@lingui/macro';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  EmailLogin,
  NoddiIcon,
  NoddiLogo,
  SMSLogin,
  SectionSpacer,
  useGetScreenDimensions,
  useLoginRedirect
} from 'noddi-ui';
import { useState } from 'react';
import styled from 'styled-components';

import routes from '../appRoutes';
import UserRegistrationForm from '../components/UserRegistrationForm';

const Container = styled.div`
  min-width: 330px;
  max-width: 550px;
  @media (width < 550px) {
    width: 90%;
    margin: 0 auto;
  }
`;

type ClickableTextProps = {
  onClick: () => void;
  children: React.ReactNode;
};

const ClickableText = ({ onClick, children }: ClickableTextProps) => (
  <Typography
    sx={{
      fontSize: 'small',
      marginTop: '1em',
      cursor: 'pointer',
      width: 'fit-content',
      padding: '3px 0.5rem',
      '&:hover': {
        textDecoration: 'underline'
      }
    }}
    onClick={onClick}
  >
    {children}
  </Typography>
);

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 24px 24px 0px 24px;
`;

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const { screenSize } = useGetScreenDimensions();
  const [isSmsLogin, setIsSmsLogin] = useState(false); // if false, email login

  const containerHeight = screenSize.height * 0.75;

  useLoginRedirect({ homePath: routes.profile.getPath() });

  return (
    <Stack justifyContent='center' alignItems='center' minHeight={containerHeight} marginY={10}>
      <Container>
        <Card elevation={16}>
          <Header>
            <div style={{ margin: 'auto 0' }}>
              <Typography variant='h3'>
                <Trans>Login</Trans>
              </Typography>
            </div>
            <NoddiLogo />
          </Header>

          <CardContent style={{ paddingTop: '0px' }}>
            {isSmsLogin ? (
              <SMSLogin userRegistrationForm={(phoneNumber) => <UserRegistrationForm phoneNumber={phoneNumber} />} />
            ) : (
              <SectionSpacer>
                <div style={{ width: '100%' }}>
                  <EmailLogin setErrorMessage={setErrorMessage} />
                </div>
              </SectionSpacer>
            )}

            <ClickableText onClick={() => setIsSmsLogin(!isSmsLogin)}>
              <Trans>
                Login with <b>{isSmsLogin ? 'email?' : ' SMS?'}</b>
              </Trans>
            </ClickableText>
            {errorMessage && (
              <p className='mt-3 text-start text-signal-danger'>
                <NoddiIcon name='Exclamation' className='mb-1' size='medium' /> {errorMessage}
              </p>
            )}
          </CardContent>
        </Card>
      </Container>
    </Stack>
  );
};

export default Login;
