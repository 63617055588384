import { Trans } from '@lingui/macro';
import { Avatar, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { WorkItem } from 'noddi-async/src/types';
import { NoddiIcon, colors } from 'noddi-ui';
import { DateFormats, format, secondsToHours } from 'noddi-util';

const WorkCard = ({ workInfo }: { workInfo: WorkItem }) => {
  return (
    <Card
      sx={{
        marginY: '12px'
      }}
    >
      <CardContent>
        <Stack
          alignItems='flex-start'
          spacing={2}
          direction={{
            xs: 'column',
            sm: 'row'
          }}
        >
          <Avatar>
            <NoddiIcon name='CalendarPlus' className='size-[70%]' />
          </Avatar>

          <div>
            <Typography variant='h5'>{format(new Date(workInfo.date), DateFormats.FULL_DAY_MONTH)}</Typography>
          </div>
        </Stack>

        <Stack divider={<Divider />}>
          <Stack
            alignItems='center'
            direction='row'
            flexWrap='wrap'
            justifyContent='space-between'
            sx={{
              px: 1,
              py: 1.5
            }}
          >
            <div>
              <Typography variant='h6' className='mb-2 text-primary-orange'>
                <Trans>Start - End address</Trans>
              </Typography>
              <Typography>
                <span className='flex items-center'>
                  <NoddiIcon name='LocationPin' size='medium' className='mr-1 mt-1' />{' '}
                  {workInfo.startAddress.streetName} {workInfo.startAddress.streetNumber} -{' '}
                  {workInfo.endAddress.streetName} {workInfo.endAddress.streetNumber}
                </span>
              </Typography>
              <Typography
                variant='h6'
                style={{
                  marginBottom: 2,
                  marginTop: 8,
                  color: colors.primary.orange
                }}
              >
                <Trans>Start - End time</Trans>
              </Typography>
              <Typography>
                <span className='flex items-center'>
                  <NoddiIcon name='ClockCircle' size='medium' className='mr-1 mt-1' />{' '}
                  {format(workInfo.startTime, DateFormats.TIME)} - {format(workInfo.endTime, DateFormats.TIME)}
                </span>
              </Typography>

              <Typography style={{ marginTop: 8 }}>
                <Trans>
                  Maximum {secondsToHours(workInfo.maxDurationInSeconds)} hours id:{workInfo.id}
                </Trans>
              </Typography>
              <Typography>
                <b>
                  <Trans>Status:</Trans>{' '}
                </b>
                {workInfo.status}
              </Typography>
            </div>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default WorkCard;
