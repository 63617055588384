import { Divider } from '@mui/material';
import { colors } from 'noddi-ui';
import styled from 'styled-components';

export const HeaderDivider = styled(Divider)`
  && {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid ${colors.primary.darkPurple};
    opacity: 1;
  }
`;
