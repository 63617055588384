import { Trans } from '@lingui/macro';
import { Divider, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { DetailedActiveRouteItem } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { NoddiButton, NoddiIcon, colors } from 'noddi-ui';
import { differenceBetweenDates } from 'noddi-util';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  routeData: DetailedActiveRouteItem;
  routeItemId: string | undefined;
  setCompletedModal: Dispatch<SetStateAction<boolean>>;
};
const CompletedModalContent = ({ routeItemId, routeData, setCompletedModal }: Props) => {
  const navigate = useNavigate();
  const [finishAppointmentComment, setFinishAppointmentComment] = useState<string>('');

  const {
    mutateAsync: completeAppointment,
    isPending: isCompleteAppointmentPending,
    isSuccess
  } = noddiAsync.usePost({
    type: URLKeys.postMarkRouteItemComplete,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteItemsDetailsForServiceWorker,
          input: { routeId: String(routeData.id), routeItemId: String(routeItemId) }
        });
        setCompletedModal(true);
      }
    }
  });

  return (
    <Stack>
      {isSuccess ? (
        <>
          <Stack divider={<Divider />}>
            <Typography variant='h6'>
              <Trans>Good job! On to the next one!</Trans>
            </Typography>
          </Stack>
          {routeData?.startedAt && (
            <Typography>
              <Trans>
                Completion time was {differenceBetweenDates(routeData.startedAt, new Date(), 'minutes')} minutes!
              </Trans>
            </Typography>
          )}
          <Stack alignItems='center' my={8}>
            <NoddiIcon size='extraLarge' name='CheckRounded' color={colors.primary.orange} className='size-25' />
          </Stack>
          <NoddiButton
            style={{ marginTop: '24px' }}
            fullWidth
            onClick={() => {
              navigate(-1);
            }}
          >
            <Trans>Go back to route view</Trans>
          </NoddiButton>{' '}
        </>
      ) : (
        <>
          <Typography variant='h6'>
            <Trans>Any comments for the customer?</Trans>
          </Typography>

          <TextField
            margin='normal'
            multiline
            value={finishAppointmentComment}
            onChange={(e) => setFinishAppointmentComment(e.target.value)}
            rows={3}
            fullWidth
            placeholder='Comments to customer (be polite)'
          />

          <NoddiButton
            style={{ marginTop: '24px' }}
            fullWidth
            loading={isCompleteAppointmentPending}
            disabled={isCompleteAppointmentPending}
            onClick={async () => {
              await completeAppointment({
                bookingId: routeData.booking.id,
                commentsWorker: finishAppointmentComment
              });
            }}
          >
            <Typography>
              <Trans>Complete appointment</Trans>
            </Typography>
          </NoddiButton>
        </>
      )}
    </Stack>
  );
};

export default CompletedModalContent;
