import { Trans, t } from '@lingui/macro';
// eslint-disable-next-line import/named
import { groupBy } from 'lodash';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, InfoPage, LoadingScreen, NoddiButton, NoddiCollapseCard, NoddiIcon, colors } from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';

type PickingListProps = {
  storageFacilityId: number;
  date: Date;
  showPersonalList: boolean;
  selectedWorkerId: number | undefined;
};

const PickingListView = ({ storageFacilityId, date, showPersonalList, selectedWorkerId }: PickingListProps) => {
  const dateString = format(date, DateFormats.DASHED_DATE_ISO_8601);

  const showGlobalPickingList = !showPersonalList || !selectedWorkerId;

  const { refetch, isPending, data, error, isRefetching } = noddiAsync.useGet({
    type: URLKeys.getCarWheelSetToPickUp,
    input: {
      storageFacilityId: storageFacilityId,
      fromDate: dateString,
      toDate: dateString,
      serviceWorkerIds: showGlobalPickingList ? [] : [selectedWorkerId]
    }
  });

  if (error) {
    return <ErrorPage apiError={error} />;
  }

  if (isPending) {
    return <LoadingScreen />;
  }

  const wheelsetGroupByRoom = groupBy(data, 'storageAreaName');

  if (!Object.keys(wheelsetGroupByRoom).length) {
    return <InfoPage title={t`No wheelsets found`} message={t`Try choosing another date or storage facility`} />;
  }

  const numberOfWheelsAtLoadingDock = data.filter((wheelset) => wheelset.isAtLoadingDock).length;

  const isMultipleRoutes = !data.every((wheelset) => wheelset.route?.id === data[0]!.route?.id);

  return (
    <>
      <div className='flex flex-col gap-4 px-3 pb-4 pt-3 md:!px-25'>
        {isMultipleRoutes && (
          <div className='flex items-center gap-2'>
            <NoddiIcon size='medium' name='Alert' />
            <h4>
              <Trans> Multiple routes found</Trans>
            </h4>
          </div>
        )}
        {Object.keys(wheelsetGroupByRoom).map((location) => {
          const sortedWheelsets = wheelsetGroupByRoom[location]!.sort((a, b) =>
            a.storageLocationName.localeCompare(b.storageLocationName)
          );
          return (
            <div key={location} className='flex flex-col gap-3'>
              <h2>{location}</h2>
              {sortedWheelsets.map(
                ({
                  id,
                  storageLocationName,
                  car: { licensePlateNumber, model, make },
                  route,
                  isAtLoadingDock,
                  pickedUpAt,
                  userGroup: { name: userGroupName },
                  type: { label: typeLabel }
                }) => (
                  <NoddiCollapseCard
                    key={id}
                    header={
                      <div className='flex flex-col gap-1'>
                        <h3>{storageLocationName}</h3>
                        <div className='flex flex-col gap-1 xs:flex-row'>
                          <p>{licensePlateNumber}</p>
                          <p className='hidden xs:block'>-</p>
                          <p className='opacity-50'>{`${model} - ${make}`}</p>
                        </div>
                        {isMultipleRoutes && route && (
                          <div className='flex gap-2'>
                            <div className='flex gap-2'>
                              <NoddiIcon name='Calendar' size='medium' />
                              <p>
                                {`${format(route.startsAt, DateFormats.HOURS)} - ${format(route.endsAt, DateFormats.HOURS)}`}
                              </p>
                            </div>
                            {!showPersonalList && (
                              <p> {route.serviceWorkers?.map((worker) => worker.nickname).join(', ')} </p>
                            )}
                          </div>
                        )}
                        <div className='flex items-center gap-1'>
                          {isAtLoadingDock && (
                            <>
                              <p className='text-signal-success'>
                                <Trans>At loading dock</Trans>
                              </p>
                              <NoddiIcon color={colors.signal.success} name='Check' />
                            </>
                          )}
                          {pickedUpAt && (
                            <>
                              <p className='text-signal-danger'>
                                <Trans> In transit </Trans>
                              </p>
                              <NoddiIcon color={colors.signal.danger} name='Cross' />
                            </>
                          )}
                        </div>
                      </div>
                    }
                    collapseBody={
                      <div className='flex flex-col gap-1'>
                        <div className='mt-3 flex items-center gap-1'>
                          <NoddiIcon name='Users' />
                          <p>{userGroupName}</p>
                        </div>
                        <div className='flex items-center gap-1'>
                          <NoddiIcon name='WheelAngle' />
                          <p>{typeLabel}</p>
                        </div>
                      </div>
                    }
                  />
                )
              )}
            </div>
          );
        })}
      </div>

      <div className='sticky bottom-0 w-full bg-secondary-white'>
        <div className='flex justify-center gap-6 p-3'>
          <div className='flex items-center gap-1'>
            <NoddiIcon name='WheelAngle' />
            <p>
              {`${numberOfWheelsAtLoadingDock}/${data.length} `} <Trans>At loading dock</Trans>
            </p>
          </div>
          <NoddiButton
            onClick={() => {
              refetch();
            }}
            loading={isPending || isRefetching}
          >
            <Trans>Refresh</Trans>
          </NoddiButton>
        </div>
      </div>
    </>
  );
};

export default PickingListView;
