import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { CapacityContribution, CapacityDay, ServiceWorker } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import {
  CapacityContributionAddressess,
  ErrorPage,
  NoddiButton,
  NoddiCard,
  NoddiFormSelect,
  NoddiFormTextInput,
  NoddiTimeSlider,
  RegisterDateCalendar,
  RegisterWorkButton,
  RegisterWorkSelectedDates,
  SimpleGridLayout,
  getLongAddressName
} from 'noddi-ui';
import { HHMMtoMinutesSinceMidnight, hoursToSeconds, secondsToHours } from 'noddi-util';
import { useState } from 'react';

import ConfirmationDialog from './ConfirmationDialog';
import { FormValues } from './FormValues';
import { getValidationSchema } from './validationSchema';

type Props = {
  capacityDays: CapacityDay[];
  serviceWorker: ServiceWorker;
  capacityContributions: CapacityContribution[];
  serviceWorkerId: number;
};

const RegisterNewWorkForm = ({ capacityDays, serviceWorker, capacityContributions, serviceWorkerId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: createCapacityContribution } = noddiAsync.usePost({
    type: URLKeys.postCapacityContribution,
    queryConfig: {
      onSuccess: () => {
        invalidateQueryExactMatch({ urlKey: URLKeys.getRegisteredWorkerCapacityContributions });
        invalidateQueryExactMatch({ urlKey: URLKeys.getServiceWorker, input: { id: serviceWorkerId } });
        setIsOpen(true);
      }
    }
  });

  // this one is really local to the calendar and represents the selected date
  // however, we also need this state in the register button component
  const [selectedDate, setSelectedDate] = useState(new Date());

  const startTime = '08:00';
  const endTime = '16:00';
  const { attributes, addresses, licenseAreas } = serviceWorker;
  const { maxDuration, licenseAreaId } = attributes;

  const addressesOptions = addresses?.map((address) => ({
    label: getLongAddressName(address),
    value: address.id
  }));
  const licenseAreaOptions = licenseAreas?.map((licenseArea) => ({
    label: licenseArea.name,
    value: licenseArea.id
  }));

  const defaultAddress = addressesOptions?.length > 0 && addressesOptions[0]?.value;

  if (!defaultAddress && defaultAddress !== 0) {
    return <ErrorPage />;
  }

  return (
    <>
      <Formik<FormValues>
        initialValues={{
          dates: [],
          licenseAreaId: licenseAreaId,
          startTime,
          endTime,
          maxDuration: secondsToHours(maxDuration),
          startAddress: defaultAddress,
          endAddress: defaultAddress
        }}
        validationSchema={getValidationSchema()}
        validateOnMount
        onSubmit={async (values) => {
          const { startAddress, endAddress, maxDuration, ...rest } = values;
          const capacityStartAddress =
            typeof startAddress === 'number' ? { startAddressId: startAddress } : { startAddress };
          const capacityEndAddress = typeof endAddress === 'number' ? { endAddressId: endAddress } : { endAddress };
          await createCapacityContribution({
            serviceWorkerId,
            ...rest,
            ...capacityStartAddress,
            ...capacityEndAddress,
            maxDuration: hoursToSeconds(maxDuration)
          });
        }}
      >
        {({ isValid, isSubmitting, values, setFieldValue, errors }) => (
          <Form>
            <SimpleGridLayout>
              <NoddiCard title={t`Select details`}>
                <NoddiFormTextInput name='maxDuration' type='number' label={t`Max duration`} />
                <CapacityContributionAddressess addressesOptions={addressesOptions} defaultAddress={defaultAddress} />
                <NoddiFormSelect name='licenseAreaId' label={t`License area`} options={licenseAreaOptions} fullWidth />
              </NoddiCard>
              <NoddiCard title={t`Select dates`}>
                <Typography style={{ fontSize: 12, marginBottom: 2 }} mt={2} px={2}>
                  🗓️ : <Trans>you have already registered for this date</Trans>
                </Typography>

                <RegisterDateCalendar
                  capacityDays={capacityDays}
                  daysToRegister={values.dates}
                  capacityContributions={capacityContributions ?? []}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
                <NoddiTimeSlider
                  min={HHMMtoMinutesSinceMidnight('05:00')}
                  max={HHMMtoMinutesSinceMidnight('23:00')}
                  initTimeStartHHMM={startTime}
                  initTimeEndHHMM={endTime}
                  onChange={(dates) => {
                    if ('start' in dates && 'end' in dates) {
                      const { start, end } = dates;
                      setFieldValue('startTime', start);
                      setFieldValue('endTime', end);
                    }
                  }}
                />

                <RegisterWorkSelectedDates
                  setDaysToRegister={(dates) => {
                    setFieldValue('dates', dates);
                  }}
                  daysToRegister={values.dates}
                />

                <RegisterWorkButton
                  daysToRegister={values.dates}
                  selectedDate={selectedDate}
                  setDaysToRegister={(dates) => {
                    setFieldValue('dates', dates);
                  }}
                />
                {errors && errors.dates && <Typography color='error'>{errors.dates}</Typography>}
              </NoddiCard>
            </SimpleGridLayout>

            <NoddiButton
              style={{ marginTop: '2rem' }}
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              type='submit'
            >{t`Register`}</NoddiButton>
          </Form>
        )}
      </Formik>
      <ConfirmationDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
export default RegisterNewWorkForm;
