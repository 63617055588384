import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import { NoddiButton, NoddiDialog, NoddiTextAreaInput } from 'noddi-ui';
import { Dispatch, SetStateAction, useState } from 'react';

import SmallClickableText from './SmallClickableText';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  routeItemId: string;
  routeId: string;
  bookingItemId: number;
};

enum DialogContent {
  UnableToMeasure = 'UnableToMeasure',
  CompleteBookingItem = 'CompleteBookingItem'
}

const ForgottenTasksModal = ({ open, setOpen, routeId, routeItemId, bookingItemId }: Props) => {
  const [dialogContent, setDialogContent] = useState<DialogContent>(DialogContent.UnableToMeasure);
  const [skipCarWheelMeasurementsReason, setSkipCarWheelMeasurementsReason] = useState<string | null>(null);
  const { noddiToast } = useNoddiToast();

  const { mutateAsync: mutateCompleteCar, isPending: isCompleteCarPending } = noddiAsync.usePost({
    type: URLKeys.postMarkCarItemInBookingComplete,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteItemsDetailsForServiceWorker,
          input: {
            routeItemId,
            routeId
          }
        });
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const stepContent = {
    [DialogContent.UnableToMeasure]: {
      render: () => (
        <Stack direction='column' height='12rem' justifyContent='center'>
          <Typography>
            <Trans>You have forgotten to measure the tires. Please measure before completing the task.</Trans>
          </Typography>

          <SmallClickableText onClick={() => setDialogContent(DialogContent.CompleteBookingItem)}>
            Unable to measure?
          </SmallClickableText>
        </Stack>
      )
    },
    [DialogContent.CompleteBookingItem]: {
      render: () => (
        <Stack gap={3} marginY={3}>
          <Typography>
            <Trans>What is the reason for not being able to measure the tires?</Trans>
          </Typography>
          <NoddiTextAreaInput
            rows={3}
            onChange={(event) => {
              setSkipCarWheelMeasurementsReason(event.target.value);
            }}
          />
          <NoddiButton
            loading={isCompleteCarPending}
            onClick={async () => {
              await mutateCompleteCar({
                routeId,
                routeItemId,
                bookingItemId,
                skipCarWheelMeasurementsReason: skipCarWheelMeasurementsReason ?? undefined
              });
              setOpen(false);
            }}
            disabled={!skipCarWheelMeasurementsReason}
          >
            <Trans>Finish this car</Trans>
          </NoddiButton>
        </Stack>
      )
    }
  };

  return (
    <NoddiDialog
      open={open}
      onClose={() => {
        setDialogContent(DialogContent.UnableToMeasure);
        setOpen(false);
      }}
    >
      {stepContent[dialogContent].render()}
    </NoddiDialog>
  );
};

export default ForgottenTasksModal;
