import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { NoddiButton, NoddiDialog, NoddiIcon, colors } from 'noddi-ui';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};
const ConfirmationDialog = ({ isOpen, setIsOpen }: Props) => {
  return (
    <NoddiDialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack>
        <Typography>
          <Trans>New work preference was created successfully!</Trans>
        </Typography>
        <Stack alignItems='center' my={8}>
          <NoddiIcon name='CheckRounded' color={colors.primary.orange} size='extraLarge' className='size-25' />
        </Stack>

        <NoddiButton
          style={{
            marginTop: '24px'
          }}
          fullWidth
          onClick={() => {
            setIsOpen(false);
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }, 100);
          }}
        >
          <Trans>Ok</Trans>
        </NoddiButton>
      </Stack>
    </NoddiDialog>
  );
};

export default ConfirmationDialog;
