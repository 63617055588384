import { Trans } from '@lingui/macro';
import { Card, CardContent, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { Route } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import { NoddiAsyncError, NoddiButton } from 'noddi-ui';
import { DateFormats, format, fromSecondsToHours, fromSecondsToMinutes, getDayNameAndDateString } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../../appRoutes';
import PaymentSection from '../../../pages/MyPayments/PaymentSection';
import CustomModal from '../../commons/Modal';

export interface RouteCardProps {
  route: Route;
  isFutureRoute?: boolean;
}

export default function RouteCard({ route, isFutureRoute }: RouteCardProps) {
  const [openStartRouteModal, setOpenStartRouteModal] = useState(false);
  const [openConfirmRouteModal, setOpenConfirmRouteModal] = useState(false);
  const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = useState(false);
  const navigate = useNavigate();
  const { noddiToast } = useNoddiToast();

  const {
    mutateAsync: mutateStartRoute,
    error: startRouteError,
    isPending: isStartRouteLoading
  } = noddiAsync.usePost({
    type: URLKeys.postStartRouteForServiceWorker,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({ urlKey: URLKeys.getRoutesServiceWorker });

        setOpenStartRouteModal(false);
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const {
    mutateAsync: mutateConfirmRoute,
    error: confirmRouteError,
    isPending: isConfirmRouteLoading
  } = noddiAsync.usePost({
    type: URLKeys.postConfirmRouteForServiceWorker,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({ urlKey: URLKeys.getRoutesServiceWorker });
        setOpenStartRouteModal(false);
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.asyncError(error);
      }
    }
  });

  return (
    <Card
      sx={{
        marginY: '12px'
      }}
    >
      <CardContent>
        <Stack justifyContent='space-between' spacing={2} direction='column' width='100%'>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
            <Typography variant='h6'>{getDayNameAndDateString(route.date)}</Typography>
          </Stack>

          <Stack width='100%' justifyContent='space-between'>
            <Stack flex={1}>
              <Typography>
                <Trans>Summary</Trans>
              </Typography>
              <Typography mt={2} variant='subtitle2'>
                {fromSecondsToHours(route.totalTimeInSeconds)} hours ({format(route.start, DateFormats.TIME)} -
                {format(route.last, DateFormats.TIME)})
              </Typography>
            </Stack>

            <div className='flex flex-col gap-3'>
              <Stack>
                <Typography mt={2} variant='subtitle2'>
                  <Trans>
                    {route.numBookings} appointments for {route.numCars} cars
                  </Trans>
                </Typography>
                <Typography mt={2} variant='subtitle2'>
                  <Trans>
                    {fromSecondsToMinutes(route.totalDrivingTimeInSeconds)} minutes driving time -{' '}
                    {Math.round((route.totalDistanceInMeters / 1000) * 10) / 10} km
                  </Trans>
                </Typography>
              </Stack>
              <div className='flex gap-2'>
                <NoddiButton
                  variant='secondary'
                  onClick={() => navigate(routes.routeDetail.getPath({ routeId: route.id }))}
                >
                  {route.startedAt ? <Trans>Open route view</Trans> : <Trans>Inspect Route</Trans>}
                </NoddiButton>

                {!route?.confirmedAt && (
                  <NoddiButton variant='secondary' onClick={() => setOpenConfirmRouteModal((prev) => !prev)}>
                    <Trans>Confirm route</Trans>
                  </NoddiButton>
                )}
              </div>
              {!route.startedAt && !isFutureRoute && (
                <NoddiButton fullWidth onClick={() => setOpenStartRouteModal((prev) => !prev)}>
                  <Trans>Start Route</Trans>
                </NoddiButton>
              )}
              <NoddiButton variant='ghost' onClick={() => setIsPaymentDetailsOpen((prev) => !prev)}>
                <Trans>See payment details</Trans>
              </NoddiButton>
            </div>

            {isPaymentDetailsOpen ? (
              <PaymentSection isFutureRoute routeSettlement={route.routeSettlement} expandedDefaultState />
            ) : null}
          </Stack>
        </Stack>
      </CardContent>

      <CustomModal
        loading={isStartRouteLoading}
        isOpen={openStartRouteModal}
        error={startRouteError}
        handleOpen={() => setOpenStartRouteModal((prev) => !prev)}
        handleClick={async () => {
          const req = {
            routeId: route.id
          };
          await mutateStartRoute(req);
        }}
        variant='info'
        text='Are you sure you want to start this route?'
      />
      <CustomModal
        loading={isConfirmRouteLoading}
        isOpen={openConfirmRouteModal}
        error={confirmRouteError}
        handleOpen={() => setOpenConfirmRouteModal((prev) => !prev)}
        handleClick={async () => {
          const req = {
            routeId: route.id
          };
          await mutateConfirmRoute(req);
        }}
        variant='info'
        text='Are you sure you want to confirm this route?'
      />
    </Card>
  );
}
