import Typography from '@mui/material/Typography';

type Props = {
  title: string;
};

export const BodyHeader = ({ title }: Props) => {
  return (
    <Typography variant='h6' style={{ marginTop: 12, marginBottom: 12 }}>
      {title}
    </Typography>
  );
};
