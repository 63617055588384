import { useAuthContext } from 'noddi-provider';
import { Outlet } from 'react-router-dom';

const Logout = () => {
  const { logout } = useAuthContext();
  logout();
  return null;
};

const PrivateLayout = () => {
  const { getToken } = useAuthContext();
  return getToken() ? <Outlet /> : <Logout />;
};

export { PrivateLayout };
