import { t } from '@lingui/macro';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ContactPerson } from 'noddi-async/src/types';
import { NoddiIcon, getAvailableContactPersons } from 'noddi-ui';

import { HeaderDivider } from '../UI/HeaderDivider';
import { ModifiedKeyValueRow } from '../UI/ModifiedKeyValueRow';
import { StyledBodyBackground } from '../UI/StyledBodyBackground';

type Props = {
  contactPersons: ContactPerson[];
};

const getPhoneNumberValue = (phoneNumber: string) => {
  return (
    <Typography variant='body2' onClick={() => window.open('tel:' + phoneNumber)}>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
      >
        <NoddiIcon name='Phone' className='mb-1 mr-2' /> {phoneNumber}
      </span>
    </Typography>
  );
};

const ContactPersonsContent = ({ contactPersons }: Props) => {
  const availableContactPersons = getAvailableContactPersons({ contactPersons });

  if (availableContactPersons.length === 0) {
    return (
      <StyledBodyBackground>
        <Typography variant='body2'>{t`No contact persons available` + '..'}</Typography>
        <Typography variant='body2'>{t`Contact OPS for further assistance`}</Typography>
      </StyledBodyBackground>
    );
  }

  return (
    <StyledBodyBackground>
      {availableContactPersons.map((contactPerson) => {
        return (
          <Box key={contactPerson.id}>
            <ModifiedKeyValueRow header={contactPerson.name} value={getPhoneNumberValue(contactPerson.phoneNumber)} />
            <HeaderDivider />
          </Box>
        );
      })}
    </StyledBodyBackground>
  );
};

export default ContactPersonsContent;
