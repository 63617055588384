import { t } from '@lingui/macro';

import routes from '../appRoutes';

export const getNavItems = () => [
  {
    items: [
      {
        path: routes.workPreferences.getPath(),
        title: t`Work preferences`
      }
    ]
  },
  {
    items: [
      {
        path: routes.routes.getPath(),
        title: t`Routes`
      }
    ]
  },
  {
    items: [
      {
        path: routes.payments.getPath(),
        title: t`Payments`
      }
    ]
  },
  {
    items: [
      {
        path: routes.feedback.getPath(),
        title: t`Feedback`
      }
    ]
  },
  {
    items: [
      {
        path: routes.pickingList.getPath(),
        title: t`Picking list`
      }
    ]
  }
];
