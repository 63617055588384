import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, LoadingScreen } from 'noddi-ui';
import { DateFormats, format, getStartOfTomorrow } from 'noddi-util';

import RegisterNewWorkForm from './RegisterNewWorkForm';

const RegisterNewWorkPreference = () => {
  const { userData } = useAuthContext();
  const selectedWorkerId = userData?.user?.serviceWorker?.id;

  const tomorrowStartOfDay = format(getStartOfTomorrow(), DateFormats.DASHED_DATE_ISO_8601);
  const { data: capacityDays, isPending: isCapacityDaysLoading } = noddiAsync.useGet({
    type: URLKeys.getCapacityDays,
    input: {
      fromDate: tomorrowStartOfDay
    },
    queryConfig: { staleTime: Infinity }
  });

  const { data: serviceWorker, isPending: isServiceWorkerLoading } = noddiAsync.useGet({
    type: URLKeys.getServiceWorker,
    input: { id: selectedWorkerId },
    queryConfig: { enabled: Boolean(selectedWorkerId), staleTime: Infinity }
  });

  const { data: capacityContributions, isPending: isWorkerContributionsLoading } = noddiAsync.useGet({
    type: URLKeys.getRegisteredWorkerCapacityContributions,
    input: { id: selectedWorkerId as number, fromDate: tomorrowStartOfDay },
    queryConfig: { enabled: Boolean(selectedWorkerId), staleTime: Infinity }
  });

  if (isCapacityDaysLoading || isWorkerContributionsLoading || isServiceWorkerLoading) {
    return <LoadingScreen />;
  }

  if (!capacityDays || !serviceWorker || !capacityContributions) {
    return <ErrorPage />;
  }

  return (
    <RegisterNewWorkForm
      serviceWorkerId={selectedWorkerId as number}
      capacityDays={capacityDays}
      serviceWorker={serviceWorker}
      capacityContributions={capacityContributions}
    />
  );
};

export default RegisterNewWorkPreference;
