import { Trans } from '@lingui/macro';
import { Box, Stack, Typography } from '@mui/material';
import { DateFormats, format } from 'noddi-util';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { RegisterWorkDateProps } from './types';

const RegisterWorkSelectedDates = ({
  daysToRegister,
  setDaysToRegister
}: Pick<RegisterWorkDateProps, 'daysToRegister' | 'setDaysToRegister'>) => {
  if (daysToRegister.length === 0) {
    return (
      <Typography color='error'>
        <Trans>No dates selected</Trans>
      </Typography>
    );
  }
  return (
    <Box px={2} width='100%'>
      <Typography variant='subtitle1' mb={2}>
        <Trans>Selected dates</Trans>
      </Typography>
      {daysToRegister
        .sort((a, b) => {
          return +new Date(a) - +new Date(b);
        })
        .map((date) => (
          <Stack direction='row' justifyContent='space-between' width='100%' mt={3} key={date.toLocaleLowerCase()}>
            <Typography>
              <b>{format(date, DateFormats.FULL_DAY_SHORT_MONTH_AND_YEAR)}</b>
              ,&nbsp;
              {format(date, DateFormats.FULL_YEAR)}
            </Typography>
            <div>
              <NoddiIcon
                name='Cross'
                size='medium'
                color={colors.primary.orange}
                className='cursor-pointer'
                onClick={() => {
                  setDaysToRegister(daysToRegister.filter((x) => x !== date));
                }}
              />
            </div>
          </Stack>
        ))}
    </Box>
  );
};

export { RegisterWorkSelectedDates };
