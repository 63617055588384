const appRoutesKeys = {
  home: 'home',
  login: 'login',
  error: 'error',
  forbiddenPage: 'forbiddenPage',
  profile: 'profile',
  workPreferences: 'workPreferences',
  routes: 'routes',
  routeDetail: 'routeDetail',
  routeItemDetail: 'routeItemDetail',
  payments: 'payments',
  feedback: 'feedback',
  carWheelSet: 'carWheelSet',
  pickingList: 'pickingList'
} as const;

type BaseRouteKeys = keyof typeof appRoutesKeys;

type StringOrNumber = string | number;

// Define the parameters for the dynamic routes
interface PathConfig {
  routeDetail: { routeId: StringOrNumber };
  routeItemDetail: { routeId: StringOrNumber; routeItemId: StringOrNumber };
  carWheelSet: { carWheelSetId: StringOrNumber };
}

type InputData<Type extends BaseRouteKeys> = Type extends keyof PathConfig ? PathConfig[Type] : void;

export type InputDataOrVoid<Type extends BaseRouteKeys> = InputData<Type> extends void ? void : InputData<Type>;

type RouteDefinition<key extends BaseRouteKeys> = {
  getPath(data: InputDataOrVoid<key>): string;
  getBasePath(): string;
};

const routes: {
  [key in BaseRouteKeys]: RouteDefinition<key>;
} = {
  home: {
    getPath: () => '/',
    getBasePath: () => '/'
  },
  login: {
    getPath: () => '/login',
    getBasePath: () => '/login'
  },
  error: {
    getPath: () => '/error',
    getBasePath: () => '/error'
  },
  forbiddenPage: {
    getPath: () => '/forbidden',
    getBasePath: () => '/forbidden'
  },
  profile: {
    getPath: () => '/profile',
    getBasePath: () => '/profile'
  },
  workPreferences: {
    getPath: () => '/work-preferences',
    getBasePath: () => '/work-preferences'
  },
  routes: {
    getPath: () => '/routes',
    getBasePath: () => '/routes'
  },
  routeDetail: {
    getPath: ({ routeId }) => `/routes/${routeId}`,
    getBasePath: () => '/routes/:routeId'
  },
  routeItemDetail: {
    getPath: ({ routeId, routeItemId }) => `/routes/${routeId}/route-item/${routeItemId}`,
    getBasePath: () => '/routes/:routeId/route-item/:routeItemId'
  },
  payments: {
    getPath: () => '/payments',
    getBasePath: () => '/payments'
  },
  feedback: {
    getPath: () => '/feedback',
    getBasePath: () => '/feedback'
  },
  carWheelSet: {
    getPath: ({ carWheelSetId }) => `/storage/car-wheel-sets/${carWheelSetId}`,
    getBasePath: () => '/storage/car-wheel-sets/:carWheelSetId'
  },
  pickingList: {
    getPath: () => '/picking-list',
    getBasePath: () => '/picking-list'
  }
};

export default routes;
