import { Trans } from '@lingui/macro';
import { Card, Modal, Stack, Typography } from '@mui/material';
import { ApiErrorMessage, NoddiButton, NoddiIcon } from 'noddi-ui';

import { ModalProps } from './interface';

const CustomModal = ({
  variant = 'info',
  handleOpen,
  handleClick,
  isOpen,
  buttonText = 'Confirm',
  text,
  loading,
  style,
  error
}: ModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleOpen}
      sx={{
        overflow: 'scroll'
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Card
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          boxShadow: 24,
          p: 4,
          ...style
        }}
      >
        {variant === 'form' ? (
          <>{text}</>
        ) : (
          <>
            <Stack id='modal-modal-title' justifyContent='center' alignItems='center'>
              {variant === 'info' ? <NoddiIcon name='Info' size='extraLarge' /> : ''}
              {variant === 'warning' || variant === 'error' ? <NoddiIcon name='Alert' size='extraLarge' /> : ''}
            </Stack>
            <Typography id='modal-modal-description' variant='h6' sx={{ mt: 2, textAlign: 'center' }}>
              {text}
            </Typography>

            <Stack direction='row' justifyContent='flex-end' mt={2} gap='5px' sx={{ width: '100%' }}>
              <NoddiButton
                style={{ marginTop: '24px' }}
                onClick={handleClick}
                disabled={Boolean(loading)}
                loading={loading}
                fullWidth
              >
                {buttonText}
              </NoddiButton>
              <NoddiButton
                style={{ marginTop: '24px' }}
                fullWidth
                onClick={handleOpen}
                disabled={Boolean(loading)}
                variant='secondary'
              >
                <Trans>Cancel</Trans>
              </NoddiButton>
            </Stack>
            {error && <ApiErrorMessage error={error} />}
          </>
        )}
      </Card>
    </Modal>
  );
};

export default CustomModal;
