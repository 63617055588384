import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { useLanguageContext } from 'noddi-provider';
import { useState } from 'react';

import { colors } from '../../../tailwind-design-preset';
import { NoddiIcon } from '../../atoms';
import { SupportedLanguages } from '../../types';

type NoddiLanguagePickerProps = {
  supportedLanguages: SupportedLanguages;
};

const NoddiLanguagePicker = ({ supportedLanguages }: NoddiLanguagePickerProps) => {
  const options = Object.entries(supportedLanguages).map(([locale, details]) => ({
    value: locale,
    label: `${details.flag} ${details.label} `
  }));

  const { currentLanguage, setCurrentLanguage } = useLanguageContext();
  const currentLanguageDetails = Object.entries(supportedLanguages).find(([locale, _]) => locale === currentLanguage);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ cursor: 'pointer', paddingLeft: '16px', paddingRight: '8px' }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Stack direction='row' sx={{ marginRight: '16px' }}>
          <Typography color='white' marginRight='14px'>
            {currentLanguageDetails?.[1].flag}
          </Typography>
          <Typography color='white' fontSize={14}>
            {currentLanguageDetails?.[1].label}
          </Typography>
        </Stack>
        <NoddiIcon name='AltArrowDown' color={colors.primary.white} size='medium' className='ml-2' />
      </Stack>
      <Select
        sx={{ visibility: 'hidden', height: 0 }}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={(event) => {
          const locale = event.target.value;
          setCurrentLanguage(locale);
        }}
        label='Language'
        value={currentLanguage}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              '&:hover': {
                backgroundColor: colors.systemColors.outlineStroke,
                '&.Mui-selected': {
                  backgroundColor: colors.systemColors.outlineStroke
                }
              },
              '&.Mui-selected': {
                backgroundColor: colors.primary.darkPurple30
              }
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { NoddiLanguagePicker };
