import { Trans, t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { Route } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen, NoddiContainer } from 'noddi-ui';
import { isToday, isTomorrow } from 'noddi-util';

import RouteCard from '../../components/ui/RouteCard';

const MyRoutes = () => {
  const { data: routesServiceWorker, isPending: isRoutesServiceWorkerLoading } = noddiAsync.useGet({
    type: URLKeys.getRoutesServiceWorker
  });

  if (isRoutesServiceWorkerLoading) {
    return <LoadingScreen />;
  }
  if (!routesServiceWorker) {
    return <ErrorPage />;
  }

  //create two lists, one for today and one for tomorrow
  const mappedRoutes = routesServiceWorker?.reduce(
    (acc, route) => {
      if (isToday(route.date)) {
        acc.today.push(route);
      } else if (isTomorrow(route.date)) {
        acc.tomorrow.push(route);
      }
      return acc;
    },
    { today: [], tomorrow: [] } as { today: Route[]; tomorrow: Route[] }
  );

  return (
    <NoddiContainer header={t`My routes`}>
      <Stack>
        <Typography variant='h6' style={{ marginBottom: 24 }}>
          <Trans>Todays route</Trans>
        </Typography>
        {mappedRoutes.today?.map((route) => {
          return <RouteCard route={route} key={route.id} />;
        })}
        {mappedRoutes.today.length === 0 && (
          <Typography>
            <Trans>There are no routes today</Trans>
          </Typography>
        )}
        <Typography variant='h6' style={{ marginTop: 24, marginBottom: 24 }}>
          <Trans>Tomorrows route</Trans>
        </Typography>
        {mappedRoutes.tomorrow?.map((route) => {
          return <RouteCard route={route} isFutureRoute key={route.id} />;
        })}
        {mappedRoutes.tomorrow.length === 0 && (
          <Typography>
            <Trans>There are no routes for tomorrow</Trans>
          </Typography>
        )}
      </Stack>
    </NoddiContainer>
  );
};

export default MyRoutes;
