import { Box, Drawer } from '@mui/material';
import { ReactNode } from 'react';

import { useIsMobile } from '../../hooks';
import { CloseCrossButton } from './Buttons';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  anchor?: 'top' | 'left' | 'bottom' | 'right';
  width?: number | string;
}
export function NoddiDrawer({ isOpen, onClose, children, anchor = 'right', width }: Props) {
  const isMobile = useIsMobile();
  const defaultWidth = isMobile ? 300 : 400;

  return (
    <Drawer anchor={anchor} open={isOpen} onClose={onClose}>
      <CloseCrossButton onClick={onClose} size='medium' />
      <Box
        sx={{ width: width ? width : anchor === 'top' || anchor === 'bottom' ? 'auto' : defaultWidth }}
        padding={2}
        height='100%'
        role='presentation'
      >
        {children}
      </Box>
    </Drawer>
  );
}
