import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { noddiAsync } from 'noddi-async';
import {
  AuthProvider,
  LanguageProvider,
  NoddiLocalizationProvider,
  ToastProvider,
  useAuthContext
} from 'noddi-provider';
import {
  Error404Page,
  ErrorPage,
  ForbiddenPage,
  NbFallback,
  NoddiCircularLoader,
  PrivateLayout,
  createTheme
} from 'noddi-ui';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { initLocales } from '../../../packages/config';
import routes from './appRoutes';
import TranslatedVerticalLayout from './layouts/TranslatedVerticalLayout';
import Login from './pages/Login';
import MyActiveRoute from './pages/MyActiveRoute';
import MyFeedback from './pages/MyFeedback';
import MyPayments from './pages/MyPayments';
import MyRoute from './pages/MyRoute';
import MyRoutes from './pages/MyRoutes';
import { PickingListWorkerApp } from './pages/PickingList';
import Profile from './pages/Profile';
import CarWheelSet from './pages/Storage/CarWheelSet';
import WorkPreferences from './pages/WorkPreferences';
import { dynamicActivateLocale } from './utils/lingui';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const defaultLanguage = 'en';

export const getSupportedLocales = () => ({
  en: { label: t`English`, flag: '🇬🇧' },
  nb: { label: t`Norwegian`, flag: '🇳🇴' }
});

function App() {
  const theme = createTheme();
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);

  useEffect(() => {
    initLocales({
      setIsLanguageLoaded,
      defaultLanguage,
      dynamicActivateLocale,
      supportedLocales: getSupportedLocales()
    });
  }, []);

  if (!isLanguageLoaded) {
    return null;
  }

  const NoddiServerContext = noddiAsync.NoddiServerContext;

  return (
    <Sentry.ErrorBoundary fallback={<NbFallback />}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <I18nProvider i18n={i18n}>
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
              searchStringToObject: queryString.parse,
              objectToSearchString: queryString.stringify
            }}
          >
            <NoddiServerContext>
              <AuthProvider>
                <LanguageProvider defaultLocale={defaultLanguage} dynamicActivateLocale={dynamicActivateLocale}>
                  <ThemeProvider theme={theme}>
                    <ToastProvider>
                      <NoddiLocalizationProvider>
                        <Router />
                      </NoddiLocalizationProvider>
                    </ToastProvider>
                  </ThemeProvider>
                </LanguageProvider>
              </AuthProvider>
            </NoddiServerContext>
          </QueryParamProvider>
        </I18nProvider>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  );
}

const Router = () => {
  const { isTokenLoginLoading } = useAuthContext();

  if (isTokenLoginLoading) {
    return (
      <div className='flex min-h-screen justify-center bg-pint-to-lighterPint-gradient'>
        <NoddiCircularLoader />
      </div>
    );
  }
  return (
    <SentryRoutes>
      <Route path={routes.login.getBasePath()} element={<Login />} />
      <Route path={routes.error.getBasePath()} element={<Error404Page />} />
      <Route path='*' element={<Error404Page />} />
      <Route path={routes.forbiddenPage.getBasePath()} element={<ForbiddenPage />} />
      <Route element={<PrivateLayout />}>
        <Route element={<TranslatedVerticalLayout />}>
          <Route path={routes.home.getBasePath()} element={<MyRoutes />} />{' '}
          <Route path={routes.profile.getBasePath()} element={<Profile />} />
          <Route path={routes.workPreferences.getBasePath()} element={<WorkPreferences />} />
          <Route path={routes.routeDetail.getBasePath()} element={<MyRoute />} />
          <Route path={routes.routes.getBasePath()} element={<MyRoutes />} />
          <Route path={routes.routeItemDetail.getBasePath()} element={<MyActiveRoute />} />
          <Route path={routes.payments.getBasePath()} element={<MyPayments />} />
          <Route path={routes.feedback.getBasePath()} element={<MyFeedback />} />
          <Route path={routes.carWheelSet.getBasePath()} element={<CarWheelSet />} />
          <Route path={routes.pickingList.getBasePath()} element={<PickingListWorkerApp />} />
        </Route>
      </Route>
    </SentryRoutes>
  );
};

export default App;
