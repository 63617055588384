/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthContext } from 'noddi-provider';
import { useContext, useEffect } from 'react';

import { axiosPrivate } from './axios';

const useAxiosPrivate = () => {
  const { getToken, logout } = useContext(AuthContext);
  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        const token = getToken();
        if (token && config.headers) {
          (config.headers as any).Authorization = `Token ${token}`;
        }

        if (config.params === undefined) {
          config.params = {};
        }

        return config;
      },
      (error) => {
        Promise.reject(error).then(() => console.error('an error occurred'));
      }
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, []);

  return axiosPrivate;
};

export default useAxiosPrivate;
