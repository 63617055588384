import { t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { StorageObject, StorageUnit } from 'noddi-async/src/types';
import { ErrorPage, NoddiButton, NoddiFormAutocomplete, NoddiIcon, usePagination } from 'noddi-ui';
import * as Yup from 'yup';

import { StorageObjectCard } from './StorageObjectCard';

type Props = {
  storageObject?: StorageObject;
  onDeliver: (storageUnitId: number) => void;
  isLoading: boolean;
};

const validationSchema = Yup.object({
  storageUnit: Yup.object({
    value: Yup.number().required(),
    label: Yup.string().required()
  }).required()
});

export type FormValues = Yup.InferType<typeof validationSchema>;

export const WheelsNotStored = ({ storageObject, onDeliver, isLoading }: Props) => {
  const { page, pageSize, search, setSearch } = usePagination();

  const {
    data: storageUnits,
    error: storageUnitsError,
    isPending: isStorageUnitsPending,
    refetch: refetchStorageUnits
  } = noddiAsync.useGet({
    type: URLKeys.getPaginatedStorageUnits,
    input: { page, pageSize, search }
  });

  if (storageUnitsError) {
    return <ErrorPage apiError={storageUnitsError} />;
  }

  const toStorageUnitOption = (unit: StorageUnit) => ({
    value: unit.id,
    label: unit.fullName
  });

  return (
    <div className='flex flex-col gap-4'>
      {storageObject ? (
        <StorageObjectCard storageObject={storageObject} />
      ) : (
        <div className='flex gap-2 rounded-2xl bg-primary-white p-3'>
          <NoddiIcon name='Wheel' size='medium' />
          <p>{t`No previous storage history`} </p>
        </div>
      )}
      <Formik<Partial<FormValues>>
        initialValues={{
          storageUnit: undefined
        }}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={async (values) => {
          if (!values.storageUnit) {
            return;
          }
          onDeliver(values.storageUnit.value);
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <NoddiFormAutocomplete
              name='storageUnit'
              placeholder={t`Search for the new storage location`}
              options={storageUnits?.results.map(toStorageUnitOption)}
              defaultOption={undefined}
              onInputChange={(inputValue) => {
                setSearch(inputValue);
                refetchStorageUnits().catch((error) => console.error(error));
              }}
              isLoading={isStorageUnitsPending}
            />

            <NoddiButton
              variant='primary'
              disabled={!isValid || isSubmitting}
              loading={isLoading || isSubmitting}
              style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}
              type='submit'
            >
              {t`Submit`}
            </NoddiButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};
