import { t } from '@lingui/macro';
import { NoddiContainer, NoddiTabs, TabItem } from 'noddi-ui';
import { useSearchParams } from 'react-router-dom';

import AllRegisteredWorPreferences from './AllRegisteredWorPreferences';
import RegisterNewWorkPreference from './RegisterNewWorkPreference';

const WorkPreferences = () => {
  const newTab: TabItem<WorkPreferencesTabItemValues> = {
    label: t`Add work preferences`,
    value: 'new',
    node: <RegisterNewWorkPreference />
  };

  const existing: TabItem<WorkPreferencesTabItemValues> = {
    label: t`Existing work preferences`,
    value: 'existing',
    node: <AllRegisteredWorPreferences />
  };

  const tabItems: TabItem<WorkPreferencesTabItemValues>[] = [newTab, existing];

  type WorkPreferencesTabItemValues = 'new' | 'existing';

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  return (
    <NoddiContainer header={tab === newTab.value ? newTab.label : existing.label}>
      <NoddiTabs<WorkPreferencesTabItemValues>
        tabItems={tabItems}
        defaultValue='existing'
        sx={{ margin: '1rem 0px' }}
      />
    </NoddiContainer>
  );
};

export default WorkPreferences;
