import { Availability, AvailabilityInterval, ContactPerson, DayOfWeekValue } from 'noddi-async/src/types';
import { DateFormats, compareDates, datesSortCompare, getDay, parseDate } from 'noddi-util';

const dayOfWeekToNumber = (dayOfWeek: DayOfWeekValue) => {
  switch (dayOfWeek) {
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
    case 'sunday':
      return 7;
    default:
      return 0;
  }
};

const getValidInterval = ({ intervals, weekday }: { intervals: AvailabilityInterval[]; weekday: number }) => {
  const currentDateTime = new Date();
  return intervals
    .filter((interval) => dayOfWeekToNumber(interval.dayOfWeek) === weekday)
    .find((interval) => {
      const format = DateFormats.TIME;

      const startTime = parseDate(interval.startTime, format, new Date());
      const endTime = parseDate(interval.endTime, format, new Date());

      return compareDates(currentDateTime, startTime, 'isAfter') && compareDates(currentDateTime, endTime, 'isBefore');
    });
};

const getDateAvailability = (availabilities: Availability[], date: Date) => {
  const dateObj = new Date(date);

  const dateAvailabilities = availabilities
    .filter(
      (availability) =>
        compareDates(availability.startDate, dateObj, 'areEqual') ||
        compareDates(availability.startDate, dateObj, 'isBefore') ||
        compareDates(availability.endDate, dateObj, 'areEqual') ||
        compareDates(availability.endDate, dateObj, 'isAfter')
    )
    .sort((a, b) => datesSortCompare(a.updatedAt, b.updatedAt, 'desc'));

  return dateAvailabilities.length ? dateAvailabilities[0] : undefined;
};

const getDefaultAvailability = (availabilities: Availability[]) => {
  const defaultAvailabilities = availabilities
    .filter((availability) => !availability.startDate && !availability.endDate)
    .sort((a, b) => datesSortCompare(a.updatedAt, b.updatedAt, 'desc'));

  return defaultAvailabilities.length ? defaultAvailabilities[0] : undefined;
};

export const getAvailableContactPersons = ({
  contactPersons,
  date = new Date()
}: {
  contactPersons: ContactPerson[];
  date?: Date;
}) => {
  const dateObj = new Date(date);
  const day = getDay(dateObj);

  // ISO 8601 standard: Monday = 1, ..., Sunday = 7
  const weekday = (day === 0 ? 7 : day) ?? 0;

  return contactPersons.filter((contactPerson) => {
    const { availabilities } = contactPerson;
    const availability = getDateAvailability(availabilities, date) || getDefaultAvailability(availabilities);
    if (!availability) {
      return false;
    }

    return getValidInterval({ intervals: availability.intervals, weekday });
  });
};
