import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  title: string;
};

export const CollapseHeader = ({ icon, title }: Props) => (
  <Typography variant='body1' style={{ marginTop: 12, marginBottom: 12 }}>
    {icon}
    {title}
  </Typography>
);
