import { Trans } from '@lingui/macro';
import Stack from '@mui/material/Stack';
import { SalesCar } from 'noddi-async/src/types';
import { NoddiButton, NoddiIcon, colors } from 'noddi-ui';

import { SalesItemDto } from '../../../hooks/apiHooks/useGetSalesItems';

type Props = {
  onRemoveCar: (value: string) => void;
  selectedCar: SalesCar;
  selectedSalesItems: SalesItemDto[];
};

const SelectedCars = ({ selectedCar, onRemoveCar, selectedSalesItems }: Props) => {
  return (
    <Stack spacing={0} style={{ marginBottom: '1em' }}>
      <div
        key={selectedCar?.licensePlateNumber ?? ''}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginTop: '0.5em'
        }}
      >
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <NoddiIcon name='Check' color={colors.primary.orange} className='mb-1' />
          <span style={{ marginLeft: '7px', fontSize: '0.9rem' }}>
            {selectedCar.make} {selectedCar.model}
            {selectedCar.licensePlateNumber && ` - ${selectedCar.licensePlateNumber}`}
          </span>
        </div>
        <NoddiButton variant='secondary' onClick={() => onRemoveCar(selectedCar?.licensePlateNumber ?? '')}>
          <Trans>Remove</Trans>
        </NoddiButton>
      </div>
      <ul>
        {selectedSalesItems.map((salesItem) => (
          <li key={salesItem.id}>{salesItem.name}</li>
        ))}
      </ul>
    </Stack>
  );
};

export default SelectedCars;
