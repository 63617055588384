import { t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { CarWheelSet, CarWheelSetSpecifications } from 'noddi-async/src/types';
import { NoddiCollapseCard, NoddiIcon, colors, getWheelSetTypeTranslation } from 'noddi-ui';

import { BodyHeader } from '../UI/BodyHeader';
import { CollapseHeader } from '../UI/CollapseHeader';
import { ModifiedKeyValueRow } from '../UI/ModifiedKeyValueRow';
import { StyledBodyBackground } from '../UI/StyledBodyBackground';

type CarWheelPairSpecificationsProps = {
  tireDimensions: string;
  tirePressurePsi?: number;
};

const CarWheelPairSpecifications = ({ tireDimensions, tirePressurePsi }: CarWheelPairSpecificationsProps) => {
  return (
    <>
      <ModifiedKeyValueRow header={t`Dimensions`} value={tireDimensions} />
      <ModifiedKeyValueRow
        header={t`Pressure`}
        value={tirePressurePsi ? `${tirePressurePsi}` + ' ' + t`PSI` : t`Unknown`}
      />
    </>
  );
};

const CarWheelSetSpecificationsBody = ({ specifications }: { specifications: CarWheelSetSpecifications }) => {
  const isStaggered = specifications.frontWheels.tireDimensions !== specifications.rearWheels.tireDimensions;

  return (
    <StyledBodyBackground>
      {isStaggered && (
        <Typography variant='body2' sx={{ color: colors.signal.danger }}>
          <NoddiIcon size='medium' name='Info' color={colors.signal.danger} className='mb-1 mr-2' />
          {t`Staggered wheels`}
        </Typography>
      )}

      <BodyHeader title={t`Front wheels`} />
      <CarWheelPairSpecifications
        tireDimensions={specifications.frontWheels.tireDimensions}
        tirePressurePsi={specifications.frontWheels.tirePressurePsi}
      />

      <BodyHeader title={t`Rear wheels`} />
      <CarWheelPairSpecifications
        tireDimensions={specifications.rearWheels.tireDimensions}
        tirePressurePsi={specifications.rearWheels.tirePressurePsi}
      />
    </StyledBodyBackground>
  );
};
const CarWheelSetCollapseCard = ({ carWheelSet }: { carWheelSet: CarWheelSet }) => {
  const specifications = carWheelSet.carWheelSetSpecifications;

  const header = (
    <CollapseHeader
      icon={<NoddiIcon name='Wheel' size='medium' color={colors.primary.darkPurple} className='mb-1 mr-2' />}
      title={getWheelSetTypeTranslation(carWheelSet.type)}
    />
  );

  return (
    <NoddiCollapseCard
      header={header}
      collapseBody={
        specifications ? (
          <CarWheelSetSpecificationsBody specifications={specifications} />
        ) : (
          <Typography>{t`No specifications available`}</Typography>
        )
      }
      sx={{
        padding: 0
      }}
    />
  );
};

export default CarWheelSetCollapseCard;
