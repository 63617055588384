import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers';

import { colors } from '../../../../tailwind-design-preset';

type NoddiDateCalendarProps = DateCalendarProps<Date> & React.RefAttributes<HTMLDivElement>;
const NoddiDateCalendar = (props: NoddiDateCalendarProps) => {
  return (
    <DateCalendar
      minDate={new Date()}
      views={['day', 'month']}
      sx={{
        '& .MuiButtonBase-root': {
          '&.MuiPickersDay-root': {
            '&.Mui-selected': {
              backgroundColor: colors.primary.orange
            }
          }
        }
      }}
      {...props}
    />
  );
};

export { NoddiDateCalendar };
