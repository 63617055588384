import { Trans } from '@lingui/macro';
import { TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { URLKeys, noddiAsync } from 'noddi-async';
import { DetailedRouteItem } from 'noddi-async/src/types';
import { NoddiButton, NoddiCollapseCard, NoddiIcon, colors, useIsMobile } from 'noddi-ui';
import { useState } from 'react';

export interface Props {
  route: DetailedRouteItem;
  overAllJobId: string;
  completed: boolean;
  estimatedDrivingTimeToAppointment?: number;
}

export default function RouteMessageBox({ route, overAllJobId, estimatedDrivingTimeToAppointment, completed }: Props) {
  const defaultOnMyWayMinutes = Math.max(estimatedDrivingTimeToAppointment ?? 0, 10);
  //input for on my way field
  const [onMyWayMinutes, setOnMyWayMinutes] = useState<number | undefined>(defaultOnMyWayMinutes);
  const [delayedMinutes, setDelayedMinutes] = useState<number | undefined>();
  const [customMessage, setCustomMessage] = useState<string>('');

  const isMobile = useIsMobile();

  const {
    mutateAsync: mutateSendArrival,
    isPending: isArrivalPending,
    isSuccess: isArrivalSuccess
  } = noddiAsync.usePost({ type: URLKeys.postSendNextArrivalSms });

  const {
    mutateAsync: mutateSendDelayed,
    isPending: isDelayedPending,
    isSuccess: isDelayedSuccess
  } = noddiAsync.usePost({ type: URLKeys.postDelayedMessage });

  const {
    mutateAsync: mutateSendCustomMessage,
    isPending: isCustomMessagePending,
    isSuccess: isCustomMessageSuccess
  } = noddiAsync.usePost({ type: URLKeys.postSendCustomSms });

  return (
    <NoddiCollapseCard
      backgroundColor={colors.primary.darkPurple30}
      preventHandleExpand
      header={
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          sx={{ backgroundColor: colors.primary.darkPurple30, width: '100%' }}
        >
          <Typography>
            <span
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <NoddiIcon name='ChatRounded' className='mr-2' />
              <Trans>Send notification</Trans>
            </span>
          </Typography>
        </Stack>
      }
      collapseBody={
        <>
          {!completed && (
            <Stack gap={1} sx={{ backgroundColor: colors.primary.darkPurple30 }} direction='column'>
              <Typography style={{ marginBottom: 4, marginTop: 12 }}>
                <Trans>Notify that you are on your way (minimum here is 10 min)</Trans>
              </Typography>
              {isArrivalSuccess ? (
                <Stack direction='row'>
                  <NoddiIcon name='Check' color={colors.primary.orange} className='mb-1 mr-2' />

                  <Typography>
                    <Trans>Message about arrival has been sent!</Trans>
                  </Typography>
                </Stack>
              ) : (
                <Stack direction='row' alignItems='center'>
                  <TextField
                    type='number'
                    fullWidth
                    placeholder='min'
                    value={onMyWayMinutes}
                    onChange={(e) => setOnMyWayMinutes(parseInt(e.target.value))}
                    color='info'
                    sx={{
                      backgroundColor: colors.primary.white,
                      marginRight: 4,
                      borderRadius: 1
                    }}
                  />{' '}
                  <NoddiButton
                    loading={isArrivalPending}
                    disabled={!onMyWayMinutes}
                    onClick={async () => {
                      await mutateSendArrival({
                        routeId: overAllJobId,
                        routeItemId: route.id,
                        numMinutes: onMyWayMinutes
                      });
                    }}
                  >
                    <Typography>
                      <Trans>Send</Trans>
                    </Typography>
                  </NoddiButton>
                </Stack>
              )}
            </Stack>
          )}
          {!completed && (
            <Stack gap={1} sx={{ backgroundColor: colors.primary.darkPurple30 }} direction='column'>
              <Typography style={{ marginBottom: 4, marginTop: 12 }}>
                <Trans>Notify that you are delayed</Trans>
              </Typography>

              {isDelayedSuccess ? (
                <Stack direction='row'>
                  <NoddiIcon name='Check' color={colors.primary.orange} className='mb-1 mr-2' />

                  <Typography>
                    <Trans>Message about delay has been sent!</Trans>
                  </Typography>
                </Stack>
              ) : (
                <Stack direction='row' alignItems='center'>
                  <TextField
                    type='number'
                    fullWidth
                    placeholder='min'
                    value={delayedMinutes}
                    onChange={(e) => setDelayedMinutes(parseInt(e.target.value))}
                    color='info'
                    sx={{
                      backgroundColor: colors.primary.white,
                      marginRight: 4,
                      borderRadius: 1
                    }}
                  />{' '}
                  <NoddiButton
                    loading={isDelayedPending}
                    disabled={!delayedMinutes}
                    onClick={async () => {
                      await mutateSendDelayed({
                        routeId: overAllJobId,
                        routeItemId: route.id,
                        numMinutes: delayedMinutes
                      });
                    }}
                  >
                    <Typography>
                      <Trans>Send</Trans>
                    </Typography>
                  </NoddiButton>
                </Stack>
              )}
            </Stack>
          )}
          <Stack marginTop={2} direction='column' justifyContent='center' alignItems='center' gap={1}>
            <Typography>
              <Trans>Notify the customer about some other issue</Trans>
            </Typography>
            {isCustomMessageSuccess ? (
              <Stack direction='row'>
                <NoddiIcon name='Check' color={colors.primary.orange} className='mb-1 mr-2' />

                <Typography>
                  <Trans>Custom message sent to the customer!</Trans>
                </Typography>
              </Stack>
            ) : (
              <Stack direction={isMobile ? 'column' : 'row'} alignItems='center' width='100%' gap={1}>
                <TextField
                  fullWidth
                  rows={2}
                  sx={{ backgroundColor: colors.primary.white, borderRadius: 1 }}
                  multiline
                  value={customMessage}
                  onChange={(e) => {
                    setCustomMessage(e.target.value);
                  }}
                  label='Custom message.. (Be Polite)'
                />
                <NoddiButton
                  loading={isCustomMessagePending}
                  disabled={!customMessage || customMessage.length < 5 || isCustomMessagePending}
                  fullWidth={isMobile}
                  onClick={() => {
                    mutateSendCustomMessage({
                      routeId: overAllJobId,
                      routeItemId: route.id,
                      message: customMessage
                    });
                  }}
                >
                  <Typography>
                    <Trans>Send</Trans>
                  </Typography>
                </NoddiButton>
              </Stack>
            )}
          </Stack>
        </>
      }
    />
  );
}
