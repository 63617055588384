import { Trans } from '@lingui/macro';
import { DateFormats, format } from 'noddi-util';

import { NoddiButton } from '../../Elements/Buttons';
import { RegisterWorkDateProps } from './types';

const RegisterWorkButton = ({
  setDaysToRegister,
  daysToRegister,
  selectedDate
}: Omit<RegisterWorkDateProps, 'setSelectedDate'>) => {
  return (
    <NoddiButton
      onClick={() => {
        // only add dates that are not in the array
        if (!daysToRegister.find((date) => date === format(selectedDate, DateFormats.DASHED_DATE_ISO_8601))) {
          setDaysToRegister(daysToRegister.concat(format(selectedDate, DateFormats.DASHED_DATE_ISO_8601)));
        }
      }}
    >
      <Trans>Add date</Trans>
    </NoddiButton>
  );
};

export { RegisterWorkButton };
