import { t } from '@lingui/macro';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ContactPerson } from 'noddi-async/src/types';

import ContactPersonsContent from './ContactPersonsContent';

type Props = {
  contactPersons: ContactPerson[];
};

const ContactPersons = ({ contactPersons }: Props) => {
  return (
    <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <Typography variant='h5' style={{ marginBottom: 24 }}>
        {t`Contact persons`}
      </Typography>

      <ContactPersonsContent contactPersons={contactPersons} />
    </Box>
  );
};

export default ContactPersons;
