import { Trans } from '@lingui/macro';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CarDetailType, CarGeneration } from 'noddi-async/src/types';
import { NoddiCollapseCard, NoddiIcon, colors } from 'noddi-ui';

import { ModifiedKeyValueColumn } from '../UI/ModifiedKeyValueColumn';
import { StyledBodyBackground } from '../UI/StyledBodyBackground';

const Header = (
  <Typography variant='body1' style={{ marginTop: 12, marginBottom: 12 }}>
    <NoddiIcon size='medium' name='Info' color={colors.primary.darkPurple} className='mb-1 mr-2' />
    <Trans>Important information</Trans>
  </Typography>
);

const Body = ({ carGeneration }: { carGeneration: CarGeneration }) => {
  const makeInformation = carGeneration.make.information;
  const modelInformation = carGeneration.model.information;
  const generationInformation = carGeneration.information;

  return (
    <StyledBodyBackground>
      <Stack spacing={2}>
        {makeInformation && <ModifiedKeyValueColumn header={carGeneration.make.name} value={makeInformation} />}

        {modelInformation && <ModifiedKeyValueColumn header={carGeneration.model.fullName} value={modelInformation} />}

        {generationInformation && (
          <ModifiedKeyValueColumn header={carGeneration.fullName} value={generationInformation} />
        )}
      </Stack>
    </StyledBodyBackground>
  );
};

const ImportantInformationCollapseCard = ({ car }: { car: CarDetailType }) => {
  const carGeneration = car.carGeneration;
  if (!carGeneration) {
    return null;
  }

  const makeInformation = carGeneration.make.information;
  const modelInformation = carGeneration.model.information;
  const generationInformation = carGeneration.information;
  if (!makeInformation && !modelInformation && !generationInformation) {
    return null;
  }

  return (
    <NoddiCollapseCard
      header={Header}
      collapseBody={<Body carGeneration={carGeneration} />}
      mountAsExpanded={true}
      sx={{
        padding: 0
      }}
    />
  );
};

export default ImportantInformationCollapseCard;
