import { Trans, t } from '@lingui/macro';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, LoadingScreen, NoddiButton, NoddiContainer, NoddiDrawer, colors } from 'noddi-ui';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import CustomModal from '../../components/commons/Modal';
import BookingServiceCard from '../../components/ui/BookingServiceCard';
import AddExtraCarModal from './AddExtraCar';
import CompletedModalContent from './CompletedModalContent';
import ContactPersons from './ContactPersons';
import BookingItemsSpecifications from './Specifications';

const MyActiveRoute = () => {
  const [isExtraCarModalOpen, setIsExtraCarModalOpen] = useState(false);
  const [completedModal, setCompletedModal] = useState(false);
  const [isCarWheelSetSpecificationsDrawerOpen, setIsCarWheelSetSpecificationsDrawerOpen] = useState(false);
  const [isContactPersonsDrawerOpen, setIsContactPersonsDrawerOpen] = useState(false);
  const { routeId, routeItemId } = useParams();

  const { data: routeData, isPending } = noddiAsync.useGet({
    type: URLKeys.getRouteItemsDetailsForServiceWorker,
    input: { routeId: String(routeId), routeItemId: String(routeItemId) }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!routeData) {
    return <ErrorPage />;
  }

  const shouldDisableFinishAppointmentButton = routeData.bookingItems.some((booking) => !booking.completedAt);

  const hasContactPersons = routeData.booking.contactPersons.length > 0;

  return (
    <NoddiContainer>
      <Stack direction='column'>
        <Typography variant='h1' style={{ marginBottom: 24 }}>
          <Trans>Appointment</Trans>
        </Typography>
        <>
          {routeData.commentsAdmin && (
            <Typography variant='body2' style={{ marginTop: 12 }}>
              <b>
                <Trans>Admin comment</Trans>
              </b>{' '}
              - {routeData.commentsAdmin}
            </Typography>
          )}
          {routeData.commentsUser && (
            <Typography variant='body2'>
              <b>
                <Trans>User comment</Trans>
              </b>{' '}
              - {routeData.commentsUser}
            </Typography>
          )}

          <>
            <NoddiButton startIcon='Hammer' onClick={() => setIsCarWheelSetSpecificationsDrawerOpen(true)}>
              {t`Specifications`}
            </NoddiButton>

            <NoddiDrawer
              isOpen={isCarWheelSetSpecificationsDrawerOpen}
              onClose={() => setIsCarWheelSetSpecificationsDrawerOpen(false)}
              anchor='right'
            >
              <BookingItemsSpecifications bookingItems={routeData.bookingItems} />
            </NoddiDrawer>
          </>

          {hasContactPersons && (
            <>
              <NoddiButton
                startIcon='Users'
                onClick={() => setIsContactPersonsDrawerOpen(true)}
                style={{ marginTop: 12 }}
              >
                {t`Contact persons`}
              </NoddiButton>

              <NoddiDrawer
                isOpen={isContactPersonsDrawerOpen}
                onClose={() => setIsContactPersonsDrawerOpen(false)}
                anchor='right'
              >
                <ContactPersons contactPersons={routeData.booking.contactPersons} />
              </NoddiDrawer>
            </>
          )}

          <Grid container>
            {routeData.bookingItems.map((bookingItem) => {
              return (
                <Grid padding={0.5} item md={4} sm={6} xs={12} key={bookingItem.id}>
                  <BookingServiceCard
                    bookingItem={bookingItem}
                    routeId={routeId ?? ''}
                    routeItemId={routeItemId ?? ''}
                    licenseAreaId={routeData.licenseAreaId}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      </Stack>

      <CustomModal
        isOpen={completedModal}
        handleOpen={() => {
          setCompletedModal((prev) => !prev);
        }}
        handleClick={() => {
          return;
        }}
        variant='form'
        text={
          <CompletedModalContent
            routeData={routeData}
            routeItemId={routeItemId}
            setCompletedModal={setCompletedModal}
          />
        }
      />
      {!routeData.completedAt ? (
        <Stack>
          {shouldDisableFinishAppointmentButton ? (
            <Typography style={{ fontSize: 12 }} color='text.secondary'>
              <Trans>You need to finish all cars in order to mark an appointment as finished</Trans>
            </Typography>
          ) : null}

          <NoddiButton
            style={{ marginTop: '24px' }}
            disabled={shouldDisableFinishAppointmentButton}
            onClick={() => {
              setCompletedModal(true);
            }}
            fullWidth
          >
            <Trans>Finish Appointment</Trans>
          </NoddiButton>
        </Stack>
      ) : null}

      <Card
        sx={{
          marginY: 2,
          p: 2,
          border: `1px solid ${colors.primary.orange}`
        }}
      >
        <CardContent>
          <div className='flex flex-col gap-3'>
            <Typography variant='h2'>
              <Trans>Register additional work</Trans>
            </Typography>
            <Typography variant='body1'>
              <Trans>Did you perform any extra services on this appointment not listed here? Add them here.</Trans>
            </Typography>
            {!!routeData.licenseAreaId && isExtraCarModalOpen && (
              <AddExtraCarModal
                isOpen={isExtraCarModalOpen}
                setOpen={setIsExtraCarModalOpen}
                licenseAreaId={routeData.licenseAreaId}
                bookingId={routeData.booking.id}
              />
            )}
            <div className='flex justify-end'>
              <NoddiButton onClick={() => setIsExtraCarModalOpen(true)} variant='secondary'>
                <Trans>Add extra car</Trans>
              </NoddiButton>
            </div>
          </div>
        </CardContent>
      </Card>
    </NoddiContainer>
  );
};

export default MyActiveRoute;
