import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { useNoddiToast } from 'noddi-provider/src/utils';
import { ErrorPage, LoadingScreen, NoddiCheckbox, NoddiDatePicker, NoddiSelect } from 'noddi-ui';
import { useEffect, useState } from 'react';

import PickingListView from './PickingList';

const defaltRamstadSlettaId = 1;

type PickingListProps = {
  disablePersonalPickingList?: boolean;
};

export const PickingList = ({ disablePersonalPickingList = false }: PickingListProps) => {
  const { data, error, isPending } = noddiAsync.useGet({
    type: URLKeys.getStorageFacilities
  });

  const [storageFacilityId, setStorageFacilityId] = useState(defaltRamstadSlettaId);
  const [date, setDate] = useState(new Date());
  const { userData } = useAuthContext();
  const selectedWorkerId = userData?.user?.serviceWorker?.id;
  const [showPersonalList, setShowPersonalList] = useState(false);

  useEffect(() => {
    if (selectedWorkerId && !disablePersonalPickingList) {
      setShowPersonalList(true);
    }
  }, [selectedWorkerId]);

  const { noddiToast } = useNoddiToast();

  if (error) {
    return <ErrorPage apiError={error} />;
  }

  if (isPending) {
    return <LoadingScreen />;
  }

  const storageFacility = data.find((facility) => facility.id === storageFacilityId);

  if (!storageFacility) {
    return <ErrorPage errorMessage={t`Failed to load storage facilities`} />;
  }

  return (
    <>
      <div className='flex flex-col gap-2 bg-primary-white px-3 pb-2 pt-3 md:!px-25'>
        <div className='flex gap-4'>
          <h1 className='max-w-32 font-bold sm:max-w-full'>
            {showPersonalList ? <Trans>My picking list</Trans> : <Trans>Global picking list</Trans>}
          </h1>
          {!disablePersonalPickingList && (
            <div className='flex items-center gap-2'>
              <label htmlFor={t`My route only`}>{t`My route only`}</label>
              <NoddiCheckbox
                name={t`My route only`}
                checked={showPersonalList}
                onChange={(event) => {
                  if (selectedWorkerId) {
                    setShowPersonalList(event.target.checked);
                  } else {
                    noddiToast.error(t`You dont have a worker id associated with your profile.`);
                  }
                }}
              />{' '}
            </div>
          )}
        </div>

        <div className='flex items-end gap-3'>
          <div className='flex w-[10rem]'>
            <NoddiDatePicker label={t`Select date`} value={date} onChange={(date) => setDate(date!)} />
          </div>
          <NoddiSelect
            className='flex w-fit flex-col'
            options={data.map((facility) => ({
              label: facility.name,
              value: facility.id
            }))}
            label={t`Choose storage facility`}
            value={storageFacility.id}
            onChange={(e) => {
              const selectedFacility = data.find((facility) => facility.id === e.target.value);
              setStorageFacilityId(selectedFacility!.id);
            }}
          />
        </div>
      </div>
      <PickingListView
        selectedWorkerId={selectedWorkerId}
        showPersonalList={showPersonalList}
        date={date}
        storageFacilityId={storageFacility.id}
      />
    </>
  );
};
