import { Trans } from '@lingui/macro';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { RouteSettlementPayment } from 'noddi-async/src/types';
import { NoddiCollapseCard, colors } from 'noddi-ui';
import { DateFormats, format, formatNorwegianCurrencyAmount } from 'noddi-util';

function FutureRoutePayment({ routeSettlement }: { routeSettlement: RouteSettlementPayment }) {
  const estimatedPayment = Math.max(
    parseInt(routeSettlement.performancePayment ?? '0'),
    parseInt(routeSettlement.computedMinimumPayment ?? '0')
  );

  const waitAndDriveTimePayment =
    parseInt(routeSettlement?.waitingTimePayment ?? '0') + parseInt(routeSettlement?.drivingTimePayment ?? '0');
  return (
    <Stack>
      {' '}
      <Typography variant='h6'>
        💵 <Trans>Estimated Payment details</Trans>
      </Typography>
      <Typography color='text.secondary' mt={2}>
        <Trans>
          We think this route will pay <b>{formatNorwegianCurrencyAmount(estimatedPayment)}</b>.
        </Trans>
      </Typography>
      <Typography color='text.secondary' mt={2}>
        <Trans>
          This is based on a payment for the actuals cars you service on{' '}
          <b>{formatNorwegianCurrencyAmount(Number(routeSettlement.sumSalesItemsLevel1Payment))}</b>, and payment for
          driving & waiting of <b> {formatNorwegianCurrencyAmount(waitAndDriveTimePayment)}</b>.
        </Trans>
      </Typography>
      <Typography color='text.secondary' mt={2}>
        <Trans>
          In addition, the mileage payment is estimated to be{' '}
          <b> {formatNorwegianCurrencyAmount(Number(routeSettlement.kilometerPayment))}</b>. Road toll cost will be
          calculated after the route is completed.
        </Trans>
      </Typography>
    </Stack>
  );
}

function getSalesItemsSummary(routeSettlement: RouteSettlementPayment) {
  return routeSettlement.salesItems.reduce(
    (acc, salesItem) => {
      const exists = acc[salesItem.id];
      if (exists) {
        acc[salesItem.id] = {
          ...salesItem,
          name: salesItem.name,
          count: exists.count + 1
        };
      } else {
        acc[salesItem.id] = {
          name: salesItem.name,
          count: 1,
          carSizes: salesItem.carSizes
        };
      }
      return acc;
    },
    {} as {
      [key: string]: {
        name: string;
        count: number;
        carSizes: { id: number; name: string }[];
      };
    }
  );
}

export default function PaymentSection({
  routeSettlement,
  expandedDefaultState,
  isFutureRoute
}: {
  routeSettlement: RouteSettlementPayment;
  expandedDefaultState?: boolean;
  isFutureRoute?: boolean;
}) {
  const totalPayment = parseInt(routeSettlement.settledPayment ?? '0') + parseInt(routeSettlement.extraPayment ?? '0');

  return (
    <NoddiCollapseCard
      sx={{ marginTop: 2 }}
      mountAsExpanded={expandedDefaultState}
      backgroundColor={colors.primary.darkPurple30}
      header={
        <Stack>
          <Typography>
            <Trans>Route Date:</Trans>{' '}
            <b>{format(new Date(routeSettlement.route.date), DateFormats.DASHED_DATE_ISO_8601)}</b>
          </Typography>
          {!isFutureRoute ? (
            <>
              <Typography>
                {isFutureRoute ? 'Estimated ' : ''}
                <Trans>Amount:</Trans> <b> {formatNorwegianCurrencyAmount(totalPayment)}</b>
              </Typography>
              <Typography>
                <Trans>Payment status: </Trans>
                <b>
                  {routeSettlement.paidAt
                    ? `paid out ${format(new Date(routeSettlement.paidAt), DateFormats.DASHED_DATE_ISO_8601)}`
                    : 'pending'}
                </b>
              </Typography>{' '}
            </>
          ) : null}
        </Stack>
      }
      collapseBody={<Payment routeSettlement={routeSettlement} isFutureRoute={isFutureRoute} />}
    />
  );
}

export const Payment = ({
  routeSettlement,
  isFutureRoute
}: {
  routeSettlement: RouteSettlementPayment;
  isFutureRoute?: boolean;
}) => {
  const salesItemSummary = getSalesItemsSummary(routeSettlement);
  const hasUnableToCompletePayment = parseInt(routeSettlement.unableToCompletePayment ?? '0') > 0;
  const waitAndDriveTimePayment =
    parseInt(routeSettlement?.waitingTimePayment ?? '0') + parseInt(routeSettlement?.drivingTimePayment ?? '0');

  const totalPayment = parseInt(routeSettlement.settledPayment ?? '0') + parseInt(routeSettlement.extraPayment ?? '0');
  return (
    <Stack>
      {isFutureRoute ? (
        <FutureRoutePayment routeSettlement={routeSettlement} />
      ) : (
        <>
          <Typography variant='h6'>
            💵 <Trans>Payment details</Trans>
          </Typography>
          <Typography color='text.secondary' mt={2}>
            <Trans>Wait & drive time payment: </Trans>
            <b>
              {formatNorwegianCurrencyAmount(
                parseInt(routeSettlement?.waitingTimePayment ?? '0') +
                  parseInt(routeSettlement?.drivingTimePayment ?? '0')
              )}
            </b>
          </Typography>
          {hasUnableToCompletePayment ? (
            <Typography color='text.secondary'>
              <Trans>
                You got <b>{formatNorwegianCurrencyAmount(Number(routeSettlement.unableToCompletePayment))}</b> from
                jobs that you were not able to complete.
              </Trans>
            </Typography>
          ) : null}
          <Typography color='text.secondary' mt={2}>
            <Trans>The payout from this route is in total</Trans> <b> {formatNorwegianCurrencyAmount(totalPayment)}</b>.{' '}
            <Trans>This is based on a payment from performed jobs of</Trans>
            <b> {formatNorwegianCurrencyAmount(Number(routeSettlement.sumSalesItemsLevel1Payment))}</b>{' '}
            <Trans>and payment from the time spent in your car of </Trans>
            <b>{formatNorwegianCurrencyAmount(waitAndDriveTimePayment)}</b>.
            {parseInt(routeSettlement.extraPayment) > 0 && (
              <>
                {' '}
                <Trans>
                  You've also received an extra payment of{' '}
                  <b>{formatNorwegianCurrencyAmount(Number(routeSettlement.extraPayment))}</b>
                  {routeSettlement.extraPaymentDescription ? ` (${routeSettlement.extraPaymentDescription}).` : '.'}
                </Trans>
              </>
            )}
          </Typography>
          <Typography color='text.secondary' mt={2}>
            <Trans>
              The additional payment from this route is{' '}
              <b> {formatNorwegianCurrencyAmount(Number(routeSettlement.kilometerPayment))}</b> for the kilometers
              traveled, and <b>{formatNorwegianCurrencyAmount(Number(routeSettlement.tollCost))}</b> in road toll.
            </Trans>
          </Typography>

          {routeSettlement.performancePayment === routeSettlement.computedMinimumPayment && (
            <Typography color='text.secondary' mt={2}>
              <Trans>Minimum payment</Trans> {isFutureRoute ? 'is estimated to' : 'was'}:
              {formatNorwegianCurrencyAmount(Number(routeSettlement.computedMinimumPayment))}
            </Typography>
          )}
        </>
      )}

      <Stack>
        <Typography variant='h6' my={2}>
          🧰 <Trans>Tasks</Trans> {isFutureRoute ? '' : 'performed'}:
        </Typography>
        {Object.entries(salesItemSummary).map(([key, { name, count, carSizes }]) => (
          <Stack key={key} style={{ backgroundColor: colors.primary.white }} borderRadius={1} mt={1} p={2}>
            <Typography>{name}</Typography>
            <Typography color='text.secondary'>
              <Trans>Car size:</Trans> {carSizes.map((carSize) => carSize.name).join(', ')}
            </Typography>
            <Typography color='text.secondary'>
              <Trans>Quantity on this route:</Trans> {count}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
