import { t } from '@lingui/macro';
import { CarWheelSetType, CarWheelSetTypes } from 'noddi-async/src/types';

export const getWheelSetTypeTranslation = (type: CarWheelSetType) => {
  switch (type.value) {
    case CarWheelSetTypes.Summer:
      return t`Summer`;
    case CarWheelSetTypes.Winter:
      return t`Winter`;
    default:
      return t`Unknown`;
  }
};
