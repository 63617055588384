import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/system';
import { PropsWithChildren } from 'react';

type SmallClickableTextProps = {
  onClick: () => void;
};

const SmallClickableText = ({ onClick, children }: PropsWithChildren<SmallClickableTextProps> & TypographyProps) => (
  <Typography
    sx={{
      fontSize: 'small',
      textDecoration: 'underline',
      marginTop: '1em',
      cursor: 'pointer',
      width: 'fit-content'
    }}
    onClick={onClick}
  >
    {children}
  </Typography>
);

export default SmallClickableText;
