import { Badge } from '@mui/material';
import { Stack } from '@mui/system';
import { PickersDay } from '@mui/x-date-pickers';
import { CapacityContribution, CapacityDay } from 'noddi-async/src/types';
import { DateFormats, format } from 'noddi-util';

import { NoddiDateCalendar } from '../../Elements/Inputs';
import { RegisterWorkDateProps } from './types';

type RegisterDateCalendarProps = Omit<RegisterWorkDateProps, 'setDaysToRegister'> & {
  capacityDays: CapacityDay[];
  capacityContributions: CapacityContribution[];
};
const RegisterDateCalendar = ({
  selectedDate,
  setSelectedDate,
  daysToRegister,
  capacityDays,
  capacityContributions
}: RegisterDateCalendarProps) => {
  return (
    <>
      <NoddiDateCalendar
        value={selectedDate}
        onChange={(date) => {
          if (!date) {
            return;
          }
          setSelectedDate(date);
        }}
        shouldDisableDate={(date) => {
          // should disable if we currently haven't created the respective capacity day yet
          const capacityDayHasBeenCreated = capacityDays?.find(
            (capacityDay) =>
              format(capacityDay.date, DateFormats.DASHED_DATE_ISO_8601) ===
              format(date, DateFormats.DASHED_DATE_ISO_8601)
          );

          return !capacityDayHasBeenCreated;
        }}
        slots={{
          day: (props) => {
            const isSelected = daysToRegister.find(
              (date) => date === format(props.day, DateFormats.DASHED_DATE_ISO_8601)
            );

            const isAlreadyRegistered = capacityContributions?.find(
              (day) =>
                format(day.date, DateFormats.DASHED_DATE_ISO_8601) ===
                format(props.day, DateFormats.DASHED_DATE_ISO_8601)
            );

            const badgeContentText = isAlreadyRegistered ? '🗓️' : isSelected ? '✅' : undefined;

            return (
              <Badge badgeContent={badgeContentText} overlap='circular'>
                <PickersDay {...props} outsideCurrentMonth={false} day={props.day} />
              </Badge>
            );
          }
        }}
      />

      <Stack px={2}></Stack>
    </>
  );
};

export { RegisterDateCalendar };
