import { t } from '@lingui/macro';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ActiveBookingItem } from 'noddi-async/src/types';
import { NoddiIcon, colors } from 'noddi-ui';

import { HeaderDivider } from '../UI/HeaderDivider';
import CarWheelSetCollapseCard from './CarWheelSetCollapseCard';
import GeneralCollapseCard from './GeneralCollapseCard';
import ImportantInformationCollapseCard from './ImportantInformationCollapseCard';

const BookingItemSpecifications = ({ bookingItem }: { bookingItem: ActiveBookingItem }) => {
  const car = bookingItem.car;
  const carDisplayName = `${car.makeName} ${car.model} - ${car.licensePlateNumber}`;

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <Typography variant='body1'>
        <NoddiIcon name='Car' size='medium' color={colors.primary.darkPurple} className='mb-1 mr-2' />
        {carDisplayName}
      </Typography>

      <HeaderDivider />

      <ImportantInformationCollapseCard car={car} />
      <GeneralCollapseCard car={car} />
      {bookingItem.car.wheelSets.map((carWheelSet) => (
        <CarWheelSetCollapseCard key={carWheelSet.id} carWheelSet={carWheelSet} />
      ))}
    </div>
  );
};

const BookingItemsSpecifications = ({ bookingItems }: { bookingItems: ActiveBookingItem[] }) => {
  return (
    <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <Typography variant='h5' style={{ marginBottom: 24 }}>
        {t`Specifications`}
      </Typography>
      {bookingItems.map((bookingItem) => (
        <BookingItemSpecifications key={bookingItem.id} bookingItem={bookingItem} />
      ))}
    </Box>
  );
};

export default BookingItemsSpecifications;
