// @ts-nocheck
import styled from '@emotion/styled';
import { Trans, t } from '@lingui/macro';
import { Divider, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ActiveBookingItem, CarWheelSetTypes, TirePosition, WheelMeasurementWorkerApp } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import { NoddiConfirmationDialog } from 'noddi-ui';
import { useState } from 'react';

const MaterialUISwitch = styled(Switch)(() => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8, <svg stroke="currentColor" fill="${encodeURIComponent(
          '#81d4fa'
        )}" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793V8.866l-3.4 1.963-.496 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.884-.237a.5.5 0 1 1 .26-.966l1.848.495L7 8 3.6 6.037l-1.85.495a.5.5 0 0 1-.258-.966l.883-.237-1.12-.646a.5.5 0 1 1 .5-.866l1.12.646-.237-.883a.5.5 0 1 1 .966-.258l.495 1.849L7.5 7.134V3.207L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 1 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v3.927l3.4-1.963.496-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495L9 8l3.4 1.963 1.849-.495a.5.5 0 0 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-3.4-1.963v3.927l1.353 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5z"></path></svg>')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#81d4fa'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#ffee58'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#ffee58',
    borderRadius: 20 / 2
  }
}));

type Props = {
  bookingItem: ActiveBookingItem;
  routeItemId: string;
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  routeId: string;
};

function findLastTireInputValue({
  position,
  isWinter,
  bookingItem
}: {
  position: TirePosition;
  isWinter: boolean;
  bookingItem: ActiveBookingItem;
}): WheelMeasurementWorkerApp | null {
  if (isWinter) {
    const findTire = bookingItem.car.wheelSets
      .find((wheelSet) => wheelSet.type.value === CarWheelSetTypes.Winter)
      ?.wheels.find((tire) => tire.position.label === position);

    // const findTire = bookingItem.car.wheelSets?.winter?.wheels.find((tire) => tire.position === position);
    if (!findTire) {
      return null;
    }

    return {
      carWheelId: findTire.id,
      value: findTire.measurements.length > 0 ? (findTire.measurements[0]?.value ?? null) : null
    };
  }

  const findTire = bookingItem.car.wheelSets
    .find((wheelSet) => wheelSet.type.value === CarWheelSetTypes.Summer)
    ?.wheels.find((tire) => tire.position.label === position);
  if (!findTire) {
    return null;
  }

  const measurementsSorted = findTire.measurements.sort((a, b) => {
    return new Date(b.measuredAt).getTime() - new Date(a.measuredAt).getTime();
  });

  return {
    carWheelId: findTire.id,
    value: measurementsSorted.length > 0 ? (measurementsSorted[0]?.value ?? null) : null
  };
}

const UpdateTireMeasuresModal = ({ bookingItem, isOpenModal, setIsOpenModal, routeItemId, routeId }: Props) => {
  const [isWinter, setIsWinter] = useState(false);
  const { noddiToast } = useNoddiToast();

  const [winterTireFL, setWinterTireFL] = useState<WheelMeasurementWorkerApp | null>(() =>
    findLastTireInputValue({
      position: 'Front left',
      isWinter: true,
      bookingItem
    })
  );
  const [winterTireFR, setWinterTireFR] = useState<WheelMeasurementWorkerApp | null>(() =>
    findLastTireInputValue({
      position: 'Front right',
      isWinter: true,
      bookingItem
    })
  );
  const [winterTireRR, setWinterTireRR] = useState<WheelMeasurementWorkerApp | null>(() =>
    findLastTireInputValue({
      position: 'Rear right',
      isWinter: true,
      bookingItem
    })
  );
  const [winterTireRL, setWinterTireRL] = useState<WheelMeasurementWorkerApp | null>(() =>
    findLastTireInputValue({
      position: 'Rear left',
      isWinter: true,
      bookingItem
    })
  );

  const [summerTireFL, setSummerTireFL] = useState<WheelMeasurementWorkerApp | null>(() =>
    findLastTireInputValue({
      position: 'Front left',
      isWinter: false,
      bookingItem
    })
  );
  const [summerTireFR, setSummerTireFR] = useState<WheelMeasurementWorkerApp | null>(() =>
    findLastTireInputValue({
      position: 'Front right',
      isWinter: false,
      bookingItem
    })
  );
  const [summerTireRR, setSummerTireRR] = useState<WheelMeasurementWorkerApp | null>(() =>
    findLastTireInputValue({
      position: 'Rear right',
      isWinter: false,
      bookingItem
    })
  );
  const [summerTireRL, setSummerTireRL] = useState<WheelMeasurementWorkerApp | null>(() =>
    findLastTireInputValue({
      position: 'Rear left',
      isWinter: false,
      bookingItem
    })
  );

  const winterTires = [winterTireFL, winterTireFR, winterTireRL, winterTireRR];
  const summerTires = [summerTireFL, summerTireFR, summerTireRL, summerTireRR];

  const { mutateAsync: updateWheelsMeasurements, isPending: isUpdateWheelsMeasurementsLoading } = noddiAsync.usePost({
    type: URLKeys.postWheelsMeasurements,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteItemsDetailsForServiceWorker,
          input: {
            routeId,
            routeItemId
          }
        });
        setIsOpenModal(false);
        noddiToast.success('Tire measurements updated successfully');
      },
      onError: () => {
        noddiToast.error('Could not update tire measurements, make sure every tire has a value in the format(6,3)');
      }
    }
  });

  const isDisabled =
    winterTires.some((tire) => tire?.value === null || tire?.value.toString() === '') ||
    summerTires.some((tire) => tire?.value === null || tire?.value.toString() === '');

  return (
    <NoddiConfirmationDialog
      open={isOpenModal}
      onClose={() => setIsOpenModal(false)}
      disableConfirm={isDisabled}
      isLoading={isUpdateWheelsMeasurementsLoading}
      cancelText={t`Cancel`}
      confirmText={t`Save`}
      description={
        <Typography variant='h5'>
          <Trans>Tire Input</Trans>
        </Typography>
      }
      onConfirm={async () => {
        await updateWheelsMeasurements({
          bookingItemId: bookingItem.id,
          measurements: [
            ...(summerTires as WheelMeasurementWorkerApp[]),
            ...(winterTires as WheelMeasurementWorkerApp[])
          ]
        });
      }}
    >
      {' '}
      <Stack>
        <Stack divider={<Divider />}>
          <Typography variant='subtitle2'>
            <Trans>Please measure the millimeter track depth for each tire at outer, inner and center.</Trans>
          </Typography>
          <Typography variant='subtitle2'>
            <Trans>Record latest value (E.g 6,3).</Trans>
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='button'>{isWinter ? 'Winter' : 'Summer'} Tyre</Typography>
          <FormControlLabel
            control={
              <MaterialUISwitch
                sx={{ m: 1 }}
                onChange={(e) => {
                  setIsWinter(e.target.checked);
                }}
                checked={isWinter}
                defaultChecked
              />
            }
            label=''
          />
        </Stack>
        {isWinter ? (
          <>
            <Stack direction='row' justifyContent='center' alignItems='center' gap={1}>
              <TextField
                label='Front Left'
                type='number'
                value={winterTireFL?.value ?? ''}
                onChange={(e) => {
                  // @ts-expect-error TODO: fix ts error
                  setWinterTireFL((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
              <TextField
                label='Front Right'
                placeholder='FR'
                type='number'
                value={winterTireFR?.value ?? ''}
                onChange={(e) => {
                  // @ts-expect-error TODO: fix ts error
                  setWinterTireFR((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
            </Stack>

            <Stack direction='row' justifyContent='center' alignItems='center' gap={1} marginY={1}>
              <TextField
                label='Rear Left'
                placeholder='RL'
                type='number'
                value={winterTireRL?.value ?? ''}
                onChange={(e) => {
                  // @ts-expect-error TODO: fix ts error
                  setWinterTireRL((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
              <TextField
                label='Rear Right'
                placeholder='RR'
                type='number'
                value={winterTireRR?.value ?? ''}
                onChange={(e) => {
                  // @ts-expect-error TODO: fix ts error
                  setWinterTireRR((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
            </Stack>
          </>
        ) : (
          <>
            <Stack direction='row' justifyContent='center' alignItems='center' gap={1}>
              <TextField
                label='Front Left'
                name='frontLeft'
                type='number'
                value={summerTireFL?.value ?? ''}
                onChange={(e) => {
                  // @ts-expect-error TODO: fix ts error
                  setSummerTireFL((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
              <TextField
                label='Front Right'
                placeholder='FR'
                type='number'
                value={summerTireFR?.value ?? ''}
                onChange={(e) => {
                  // @ts-expect-error TODO: fix ts error
                  setSummerTireFR((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
            </Stack>

            <Stack direction='row' justifyContent='center' alignItems='center' gap={1} marginY={1}>
              <TextField
                label='Rear Left'
                placeholder='RL'
                type='number'
                value={summerTireRL?.value ?? ''}
                onChange={(e) => {
                  // @ts-expect-error TODO: fix ts error
                  setSummerTireRL((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
              <TextField
                label='Rear Right'
                placeholder='RR'
                type='number'
                value={summerTireRR?.value ?? ''}
                onChange={(e) => {
                  // @ts-expect-error TODO: fix ts error
                  setSummerTireRR((prev) => ({
                    ...prev,
                    value: e.target.value
                  }));
                }}
              />
            </Stack>
          </>
        )}

        {isDisabled ? (
          <Typography color='text.secondary' textAlign='center' style={{ fontSize: 12 }}>
            <Trans>All tires must have a value</Trans>
          </Typography>
        ) : null}
        <Typography color='text.secondary'>
          <Trans>Both summer and winter tires must be measured</Trans>
        </Typography>
      </Stack>
    </NoddiConfirmationDialog>
  );
};

export default UpdateTireMeasuresModal;
