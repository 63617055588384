import { t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { CarDetailType, CarGeneration } from 'noddi-async/src/types';
import { NoddiCollapseCard, NoddiIcon, colors } from 'noddi-ui';

import { CollapseHeader } from '../UI/CollapseHeader';
import { ModifiedKeyValueRow } from '../UI/ModifiedKeyValueRow';
import { StyledBodyBackground } from '../UI/StyledBodyBackground';

const Body = ({ carGeneration }: { carGeneration: CarGeneration }) => {
  return (
    <StyledBodyBackground>
      <ModifiedKeyValueRow
        header={t`Torque steel`}
        value={
          carGeneration.wheelLugNutTorqueSteelNm
            ? `${carGeneration.wheelLugNutTorqueSteelNm}` + ' ' + t`Nm`
            : t`Unknown`
        }
      />
      <ModifiedKeyValueRow
        header={t`Torque aluminium`}
        value={
          carGeneration.wheelLugNutTorqueAluminiumNm
            ? `${carGeneration.wheelLugNutTorqueAluminiumNm}` + ' ' + t`Nm`
            : t`Unknown`
        }
      />
    </StyledBodyBackground>
  );
};
const GeneralCollapseCard = ({ car }: { car: CarDetailType }) => {
  const carGeneration = car.carGeneration;

  const header = (
    <CollapseHeader
      icon={<NoddiIcon name='Hammer' size='medium' color={colors.primary.darkPurple} className='mb-1 mr-2' />}
      title={t`General`}
    />
  );

  return (
    <NoddiCollapseCard
      header={header}
      collapseBody={
        carGeneration ? (
          <Body carGeneration={carGeneration} />
        ) : (
          <Typography>{t`No specifications available`}</Typography>
        )
      }
      mountAsExpanded={true}
      sx={{
        padding: 0
      }}
    />
  );
};

export default GeneralCollapseCard;
