import { t } from '@lingui/macro';
import { Dispatch, useState } from 'react';

import { NoddiButton, NoddiFormAddressPicker, NoddiFormSelect } from '../../Elements';

interface CapacityAddressProps extends Props {
  isCustomAddress: boolean;
  setIsCustomAddress: Dispatch<React.SetStateAction<boolean>>;
  formLabel: string;
  label: string;
}

function CapacityContributionAddress({
  addressesOptions,
  defaultAddress,
  isCustomAddress,
  setIsCustomAddress,
  formLabel,
  label
}: CapacityAddressProps) {
  return (
    <div>
      {isCustomAddress ? (
        <NoddiFormAddressPicker name={formLabel} label={label} addresses={[]} />
      ) : (
        <NoddiFormSelect name={formLabel} label={label} options={addressesOptions} value={defaultAddress} fullWidth />
      )}
      <NoddiButton onClick={() => setIsCustomAddress((prev) => !prev)} variant='link'>
        {isCustomAddress ? t`Choose from existing address?` : t`Choose a different address?`}
      </NoddiButton>
    </div>
  );
}

interface Props {
  addressesOptions: { label: string; value: number | undefined }[];
  defaultAddress: number | string;
}

export function CapacityContributionAddressess({ addressesOptions, defaultAddress }: Props) {
  const [isCustomStartAddress, setIsCustomStartAddress] = useState(false);
  const [isCustomEndAddress, setIsCustomEndAddress] = useState(false);
  return (
    <>
      <CapacityContributionAddress
        setIsCustomAddress={setIsCustomStartAddress}
        label={t`Start address`}
        formLabel='startAddress'
        addressesOptions={addressesOptions}
        defaultAddress={defaultAddress}
        isCustomAddress={isCustomStartAddress}
      />
      <CapacityContributionAddress
        setIsCustomAddress={setIsCustomEndAddress}
        isCustomAddress={isCustomEndAddress}
        formLabel='endAddress'
        label={t`End address`}
        addressesOptions={addressesOptions}
        defaultAddress={defaultAddress}
      />
    </>
  );
}
