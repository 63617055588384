import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, NoddiContainer, NoddiTable, usePagination } from 'noddi-ui';
import { DateFormats, format, formatNorwegianCurrencyAmount } from 'noddi-util';
import { useEffect } from 'react';

import { Payment } from './PaymentSection';

const MyPayments = () => {
  const { userData } = useAuthContext();
  const { page, setPage, pageSize, setPageSize } = usePagination();

  const {
    data: routeSettlements,
    refetch,
    isPending: isRouteSettlementsLoading
  } = noddiAsync.useGet({
    type: URLKeys.getRouteSettlementsForWorker,
    input: {
      page,
      pageSize,
      userId: String(userData?.user.id),
      type: 1
    },
    queryConfig: { enabled: !!userData?.user.id }
  });

  useEffect(() => {
    refetch();
  }, [page, pageSize]);

  if (!routeSettlements && !isRouteSettlementsLoading) {
    return <ErrorPage />;
  }

  const tableItems = routeSettlements?.results.map((item) => {
    return {
      renderItems: [
        format(new Date(item.route.date), DateFormats.DASHED_DATE_ISO_8601),
        `${formatNorwegianCurrencyAmount(parseInt(item.settledPayment ?? '0') + parseInt(item.extraPayment ?? '0'))}`,
        item.paidAt ? `paid out ${format(new Date(item.paidAt), DateFormats.DASHED_DATE_ISO_8601)}` : 'pending'
      ],
      item,
      collapseContent: <Payment routeSettlement={item} />
    };
  });

  return (
    <NoddiContainer header={t`💰 Payments`}>
      <div style={{ marginTop: '1rem' }}>
        <NoddiTable
          count={routeSettlements?.count}
          items={tableItems}
          isLoading={isRouteSettlementsLoading}
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          headers={[t`Route Date`, t`Amount`, t`Payment status`]}
        />
      </div>
    </NoddiContainer>
  );
};

export default MyPayments;
