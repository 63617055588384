import { Trans, t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AddressProps, LicenseAreaListItem } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import {
  ErrorPage,
  LoadingScreen,
  NoddiButton,
  NoddiFormAddressPicker,
  NoddiFormSelect,
  NoddiFormTextInput,
  SectionSpacer
} from 'noddi-ui';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import userRegistrationFormSchema from './validators';

interface IFormInput {
  firstName: string;
  surname: string;
  email: string;
  addressStart: AddressProps | null;
  addressEnd: AddressProps | null;
  licenseAreaIds: number[];
  languageCodes: string[];
  password: string;
  confirmPassword: string;
}

const getLicenseAreaOptions = (licenseAreas: LicenseAreaListItem[]) => {
  return licenseAreas
    ? licenseAreas.map((licenseArea: LicenseAreaListItem) => {
        return { value: licenseArea.id, label: licenseArea.name };
      })
    : [];
};

const UserRegistrationForm = ({ phoneNumber }: { phoneNumber: string }) => {
  const { loginUser } = useAuthContext();

  const { data: licenseAreas, isPending: isLicenseAreasLoading } = noddiAsync.useGet({
    type: URLKeys.getLicenseAreas
  });

  const navigate = useNavigate();
  const initialValues = {
    firstName: '',
    surname: '',
    email: '',
    addressStart: null,
    addressEnd: null,
    licenseAreaIds: [],
    languageCodes: ['EN'],
    password: '',
    confirmPassword: ''
  };

  const onSubmit = (data: IFormInput) => {
    if (!data.addressEnd || !data.addressStart) {
      return;
    }

    createUser({
      firstName: data.firstName,
      lastName: data.surname,
      phoneNumber: phoneNumber,
      isWorker: true,
      startAddress: data.addressStart,
      endAddress: data.addressEnd,
      email: data.email,
      licenseAreaIds: data.licenseAreaIds,
      languageCodes: data.languageCodes,
      password: data.password,
      confirmPassword: data.confirmPassword
    });
  };

  // use form state
  const { mutateAsync: createUser, isPending } = noddiAsync.usePost({
    type: URLKeys.postUser,
    queryConfig: {
      onSuccess: (data) => {
        loginUser(data.data); // this is actually a user sign-in
        navigate(`${routes.workPreferences.getPath()}?tab=new`);
      }
    }
  });

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, [ref]);

  if (isLicenseAreasLoading) {
    return <LoadingScreen />;
  }
  if (!licenseAreas) {
    return <ErrorPage />;
  }

  const licenseAreaOptions = getLicenseAreaOptions(licenseAreas);

  const languageOptions = [
    { label: 'Norwegian', value: 'NO' },
    { label: 'English', value: 'EN' }
  ];

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={userRegistrationFormSchema()}>
      {({ isValid, isSubmitting }) => (
        <Form>
          <Typography variant='h4'>
            <Trans>User information</Trans>
          </Typography>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput ref={ref} name='firstName' label={t`First name`} />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput name='surname' label={t`Surname`} />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput name='email' label={t`Email`} placeholder={t`test@example.com`} />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput autoComplete='new-password' name='password' label={t`Password`} type='password' />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput
              autoComplete='new-password'
              name='confirmPassword'
              label={t`Confirm password`}
              type='password'
            />{' '}
          </SectionSpacer>
          <Typography marginTop={4} variant='subtitle1'>
            <Trans>At which license area can you work?</Trans>
          </Typography>
          <NoddiFormSelect
            name='licenseAreaIds'
            label={t`License area`}
            options={licenseAreaOptions}
            multiple
            fullWidth
          />
          <Typography marginTop={3} variant='subtitle1'>
            <Trans>Which languages do you speak?</Trans>
          </Typography>
          <NoddiFormSelect name='languageCodes' label={t`Language`} options={languageOptions} multiple fullWidth />
          <Typography marginTop={3} variant='subtitle1'>
            <Trans> At what place do you want to start you work</Trans> {t`(include street number)`}?
          </Typography>
          <NoddiFormAddressPicker addresses={[]} name='addressStart' label={t`Address`} />
          <Typography marginTop={3} variant='subtitle1'>
            <Trans>At what place do you want to end you work</Trans> {t`(include street number)`}?
          </Typography>
          <NoddiFormAddressPicker addresses={[]} name='addressEnd' label={t`Address`} />

          <NoddiButton
            style={{ marginTop: '24px' }}
            type='submit'
            fullWidth
            disabled={!isValid}
            loading={isSubmitting || isPending}
          >
            <Trans>Create user</Trans>
          </NoddiButton>
        </Form>
      )}
    </Formik>
  );
};

export default UserRegistrationForm;
