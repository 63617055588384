import { t } from '@lingui/macro';
import * as yup from 'yup';

const userRegistrationFormSchema = () =>
  yup.object({
    name: yup
      .string()
      .required(t`This field is required`)
      .min(2, t`This field must be at least 2 characters long`),

    email: yup
      .string()
      .required(t`This field is required`)
      .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, t`Need to be a valid email`),

    address: yup.object().shape({
      streetName: yup.string().required(t`Need to be an address with street name`),
      streetNumber: yup.string().required(t`Need to be an address with street name`)
    }),
    password: yup
      .string()
      .required(t`This field is required`)
      .min(8, t`Password must be at least 8 characters long`),
    confirmPassword: yup
      .string()
      .required(t`This field is required`)
      .oneOf([yup.ref('password')], t`Passwords must match`)
  });

export default userRegistrationFormSchema;
